// @ts-nocheck
import { GraphQLResolveInfo, SelectionSetNode, FieldNode, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { gql } from '@graphql-mesh/utils';

import type { GetMeshOptions } from '@graphql-mesh/runtime';
import type { YamlConfig } from '@graphql-mesh/types';
import { PubSub } from '@graphql-mesh/utils';
import { DefaultLogger } from '@graphql-mesh/utils';
import MeshCache from "@graphql-mesh/cache-localforage";
import { fetch as fetchFn } from '@whatwg-node/fetch';

import { MeshResolvedSource } from '@graphql-mesh/runtime';
import { MeshTransform, MeshPlugin } from '@graphql-mesh/types';
import GraphqlHandler from "@graphql-mesh/graphql"
import BareMerger from "@graphql-mesh/merger-bare";
import { printWithCache } from '@graphql-mesh/utils';
import { usePersistedOperations } from '@graphql-yoga/plugin-persisted-operations';
import { createMeshHTTPHandler, MeshHTTPHandler } from '@graphql-mesh/http';
import { getMesh, ExecuteMeshFn, SubscribeMeshFn, MeshContext as BaseMeshContext, MeshInstance } from '@graphql-mesh/runtime';
import { MeshStore, FsStoreStorageAdapter } from '@graphql-mesh/store';
import { path as pathModule } from '@graphql-mesh/cross-helpers';
import { ImportFn } from '@graphql-mesh/types';
import type { MagnifyCashTypes } from './sources/magnifyCash/types';
import * as importedModule$0 from "./sources/magnifyCash/introspectionSchema";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };



/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigInt: { input: any; output: any; }
};

export type Query = {
  loan?: Maybe<Loan>;
  loans: LoanPage;
  user?: Maybe<User>;
  users: UserPage;
  erc20?: Maybe<Erc20>;
  erc20s: Erc20Page;
  loanConfig?: Maybe<LoanConfig>;
  loanConfigs: LoanConfigPage;
  lendingDesk?: Maybe<LendingDesk>;
  lendingDesks: LendingDeskPage;
  protocolInfo?: Maybe<ProtocolInfo>;
  protocolInfos: ProtocolInfoPage;
  nftCollection?: Maybe<NftCollection>;
  nftCollections: NftCollectionPage;
  nftCollectionErc20?: Maybe<NftCollectionErc20>;
  nftCollectionErc20s: NftCollectionErc20Page;
  _meta?: Maybe<_meta>;
};


export type QueryloanArgs = {
  id: Scalars['String']['input'];
};


export type QueryloansArgs = {
  where?: InputMaybe<LoanFilter>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryuserArgs = {
  id: Scalars['String']['input'];
};


export type QueryusersArgs = {
  where?: InputMaybe<UserFilter>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type Queryerc20Args = {
  id: Scalars['String']['input'];
};


export type Queryerc20sArgs = {
  where?: InputMaybe<Erc20Filter>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryloanConfigArgs = {
  id: Scalars['String']['input'];
};


export type QueryloanConfigsArgs = {
  where?: InputMaybe<LoanConfigFilter>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerylendingDeskArgs = {
  id: Scalars['String']['input'];
};


export type QuerylendingDesksArgs = {
  where?: InputMaybe<LendingDeskFilter>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryprotocolInfoArgs = {
  id: Scalars['String']['input'];
};


export type QueryprotocolInfosArgs = {
  where?: InputMaybe<ProtocolInfoFilter>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerynftCollectionArgs = {
  id: Scalars['String']['input'];
};


export type QuerynftCollectionsArgs = {
  where?: InputMaybe<NftCollectionFilter>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerynftCollectionErc20Args = {
  id: Scalars['String']['input'];
};


export type QuerynftCollectionErc20sArgs = {
  where?: InputMaybe<NftCollectionErc20Filter1>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Loan = {
  id: Scalars['String']['output'];
  nftId: Scalars['BigInt']['output'];
  amount: Scalars['BigInt']['output'];
  lender: User;
  status: LoanStatus;
  txHash: Scalars['String']['output'];
  borrower: User;
  duration: Scalars['BigInt']['output'];
  interest: Scalars['BigInt']['output'];
  lenderId: Scalars['String']['output'];
  startTime: Scalars['BigInt']['output'];
  borrowerId: Scalars['String']['output'];
  lendingDesk: LendingDesk;
  lendingDeskId: Scalars['String']['output'];
  nftCollection: NftCollection;
  amountPaidBack: Scalars['BigInt']['output'];
  nftCollectionId: Scalars['String']['output'];
};

export type User = {
  id: Scalars['String']['output'];
  loansTaken?: Maybe<LoanPage>;
  loansIssued?: Maybe<LoanPage>;
  lendingDesks?: Maybe<LendingDeskPage>;
  loansTakenCount: Scalars['BigInt']['output'];
  loansIssuedCount: Scalars['BigInt']['output'];
  loansTakenResolvedCount: Scalars['BigInt']['output'];
  loansIssuedResolvedCount: Scalars['BigInt']['output'];
  loansTakenDefaultedCount: Scalars['BigInt']['output'];
  loansIssuedDefaultedCount: Scalars['BigInt']['output'];
};


export type UserloansTakenArgs = {
  where?: InputMaybe<LoanFilter>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type UserloansIssuedArgs = {
  where?: InputMaybe<LoanFilter>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type UserlendingDesksArgs = {
  where?: InputMaybe<LendingDeskFilter>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type LoanPage = {
  items: Array<Loan>;
  pageInfo: PageInfo;
};

export type PageInfo = {
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
  endCursor?: Maybe<Scalars['String']['output']>;
};

export type LoanFilter = {
  AND?: InputMaybe<Array<InputMaybe<LoanFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LoanFilter>>>;
  id?: InputMaybe<Scalars['String']['input']>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_contains?: InputMaybe<Scalars['String']['input']>;
  id_not_contains?: InputMaybe<Scalars['String']['input']>;
  id_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_ends_with?: InputMaybe<Scalars['String']['input']>;
  id_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  nftId?: InputMaybe<Scalars['BigInt']['input']>;
  nftId_not?: InputMaybe<Scalars['BigInt']['input']>;
  nftId_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  nftId_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  nftId_gt?: InputMaybe<Scalars['BigInt']['input']>;
  nftId_lt?: InputMaybe<Scalars['BigInt']['input']>;
  nftId_gte?: InputMaybe<Scalars['BigInt']['input']>;
  nftId_lte?: InputMaybe<Scalars['BigInt']['input']>;
  amount?: InputMaybe<Scalars['BigInt']['input']>;
  amount_not?: InputMaybe<Scalars['BigInt']['input']>;
  amount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  amount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  amount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  lender_?: InputMaybe<LoanUserFilter>;
  status?: InputMaybe<LoanStatus>;
  status_not?: InputMaybe<LoanStatus>;
  status_in?: InputMaybe<Array<InputMaybe<LoanStatus>>>;
  status_not_in?: InputMaybe<Array<InputMaybe<LoanStatus>>>;
  txHash?: InputMaybe<Scalars['String']['input']>;
  txHash_not?: InputMaybe<Scalars['String']['input']>;
  txHash_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  txHash_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  txHash_gt?: InputMaybe<Scalars['String']['input']>;
  txHash_lt?: InputMaybe<Scalars['String']['input']>;
  txHash_gte?: InputMaybe<Scalars['String']['input']>;
  txHash_lte?: InputMaybe<Scalars['String']['input']>;
  borrower_?: InputMaybe<LoanUserFilter1>;
  duration?: InputMaybe<Scalars['BigInt']['input']>;
  duration_not?: InputMaybe<Scalars['BigInt']['input']>;
  duration_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  duration_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  duration_gt?: InputMaybe<Scalars['BigInt']['input']>;
  duration_lt?: InputMaybe<Scalars['BigInt']['input']>;
  duration_gte?: InputMaybe<Scalars['BigInt']['input']>;
  duration_lte?: InputMaybe<Scalars['BigInt']['input']>;
  interest?: InputMaybe<Scalars['BigInt']['input']>;
  interest_not?: InputMaybe<Scalars['BigInt']['input']>;
  interest_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  interest_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  interest_gt?: InputMaybe<Scalars['BigInt']['input']>;
  interest_lt?: InputMaybe<Scalars['BigInt']['input']>;
  interest_gte?: InputMaybe<Scalars['BigInt']['input']>;
  interest_lte?: InputMaybe<Scalars['BigInt']['input']>;
  lenderId?: InputMaybe<Scalars['String']['input']>;
  lenderId_not?: InputMaybe<Scalars['String']['input']>;
  lenderId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lenderId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lenderId_gt?: InputMaybe<Scalars['String']['input']>;
  lenderId_lt?: InputMaybe<Scalars['String']['input']>;
  lenderId_gte?: InputMaybe<Scalars['String']['input']>;
  lenderId_lte?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['BigInt']['input']>;
  startTime_not?: InputMaybe<Scalars['BigInt']['input']>;
  startTime_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  startTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  startTime_gt?: InputMaybe<Scalars['BigInt']['input']>;
  startTime_lt?: InputMaybe<Scalars['BigInt']['input']>;
  startTime_gte?: InputMaybe<Scalars['BigInt']['input']>;
  startTime_lte?: InputMaybe<Scalars['BigInt']['input']>;
  borrowerId?: InputMaybe<Scalars['String']['input']>;
  borrowerId_not?: InputMaybe<Scalars['String']['input']>;
  borrowerId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  borrowerId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  borrowerId_gt?: InputMaybe<Scalars['String']['input']>;
  borrowerId_lt?: InputMaybe<Scalars['String']['input']>;
  borrowerId_gte?: InputMaybe<Scalars['String']['input']>;
  borrowerId_lte?: InputMaybe<Scalars['String']['input']>;
  lendingDesk_?: InputMaybe<LoanLendingDeskFilter>;
  lendingDeskId?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_not?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lendingDeskId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lendingDeskId_contains?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_not_contains?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_starts_with?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_ends_with?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  nftCollection_?: InputMaybe<LoanNftCollectionFilter>;
  amountPaidBack?: InputMaybe<Scalars['BigInt']['input']>;
  amountPaidBack_not?: InputMaybe<Scalars['BigInt']['input']>;
  amountPaidBack_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  amountPaidBack_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  amountPaidBack_gt?: InputMaybe<Scalars['BigInt']['input']>;
  amountPaidBack_lt?: InputMaybe<Scalars['BigInt']['input']>;
  amountPaidBack_gte?: InputMaybe<Scalars['BigInt']['input']>;
  amountPaidBack_lte?: InputMaybe<Scalars['BigInt']['input']>;
  nftCollectionId?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_not?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nftCollectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nftCollectionId_gt?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_lt?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_gte?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_lte?: InputMaybe<Scalars['String']['input']>;
};

export type LoanUserFilter = {
  id?: InputMaybe<Scalars['String']['input']>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_gt?: InputMaybe<Scalars['String']['input']>;
  id_lt?: InputMaybe<Scalars['String']['input']>;
  id_gte?: InputMaybe<Scalars['String']['input']>;
  id_lte?: InputMaybe<Scalars['String']['input']>;
  loansTakenCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansTakenCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansTakenCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansIssuedCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansIssuedCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenResolvedCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenResolvedCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenResolvedCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansTakenResolvedCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansTakenResolvedCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenResolvedCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenResolvedCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenResolvedCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedResolvedCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedResolvedCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedResolvedCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansIssuedResolvedCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansIssuedResolvedCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedResolvedCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedResolvedCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedResolvedCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenDefaultedCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenDefaultedCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenDefaultedCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansTakenDefaultedCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansTakenDefaultedCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenDefaultedCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenDefaultedCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenDefaultedCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedDefaultedCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedDefaultedCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedDefaultedCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansIssuedDefaultedCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansIssuedDefaultedCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedDefaultedCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedDefaultedCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedDefaultedCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
};

export type LoanStatus =
  | 'Active'
  | 'Resolved'
  | 'Defaulted';

export type LoanUserFilter1 = {
  id?: InputMaybe<Scalars['String']['input']>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_gt?: InputMaybe<Scalars['String']['input']>;
  id_lt?: InputMaybe<Scalars['String']['input']>;
  id_gte?: InputMaybe<Scalars['String']['input']>;
  id_lte?: InputMaybe<Scalars['String']['input']>;
  loansTakenCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansTakenCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansTakenCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansIssuedCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansIssuedCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenResolvedCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenResolvedCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenResolvedCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansTakenResolvedCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansTakenResolvedCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenResolvedCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenResolvedCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenResolvedCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedResolvedCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedResolvedCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedResolvedCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansIssuedResolvedCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansIssuedResolvedCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedResolvedCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedResolvedCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedResolvedCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenDefaultedCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenDefaultedCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenDefaultedCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansTakenDefaultedCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansTakenDefaultedCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenDefaultedCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenDefaultedCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenDefaultedCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedDefaultedCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedDefaultedCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedDefaultedCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansIssuedDefaultedCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansIssuedDefaultedCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedDefaultedCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedDefaultedCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedDefaultedCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
};

export type LoanLendingDeskFilter = {
  id?: InputMaybe<Scalars['String']['input']>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_contains?: InputMaybe<Scalars['String']['input']>;
  id_not_contains?: InputMaybe<Scalars['String']['input']>;
  id_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_ends_with?: InputMaybe<Scalars['String']['input']>;
  id_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<LendingDeskStatus>;
  status_not?: InputMaybe<LendingDeskStatus>;
  status_in?: InputMaybe<Array<InputMaybe<LendingDeskStatus>>>;
  status_not_in?: InputMaybe<Array<InputMaybe<LendingDeskStatus>>>;
  balance?: InputMaybe<Scalars['BigInt']['input']>;
  balance_not?: InputMaybe<Scalars['BigInt']['input']>;
  balance_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  balance_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  balance_gt?: InputMaybe<Scalars['BigInt']['input']>;
  balance_lt?: InputMaybe<Scalars['BigInt']['input']>;
  balance_gte?: InputMaybe<Scalars['BigInt']['input']>;
  balance_lte?: InputMaybe<Scalars['BigInt']['input']>;
  erc20Id?: InputMaybe<Scalars['String']['input']>;
  erc20Id_not?: InputMaybe<Scalars['String']['input']>;
  erc20Id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  erc20Id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  erc20Id_gt?: InputMaybe<Scalars['String']['input']>;
  erc20Id_lt?: InputMaybe<Scalars['String']['input']>;
  erc20Id_gte?: InputMaybe<Scalars['String']['input']>;
  erc20Id_lte?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['String']['input']>;
  ownerId_not?: InputMaybe<Scalars['String']['input']>;
  ownerId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ownerId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ownerId_gt?: InputMaybe<Scalars['String']['input']>;
  ownerId_lt?: InputMaybe<Scalars['String']['input']>;
  ownerId_gte?: InputMaybe<Scalars['String']['input']>;
  ownerId_lte?: InputMaybe<Scalars['String']['input']>;
  netProfit?: InputMaybe<Scalars['BigInt']['input']>;
  netProfit_not?: InputMaybe<Scalars['BigInt']['input']>;
  netProfit_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  netProfit_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  netProfit_gt?: InputMaybe<Scalars['BigInt']['input']>;
  netProfit_lt?: InputMaybe<Scalars['BigInt']['input']>;
  netProfit_gte?: InputMaybe<Scalars['BigInt']['input']>;
  netProfit_lte?: InputMaybe<Scalars['BigInt']['input']>;
  loansCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  amountBorrowed?: InputMaybe<Scalars['BigInt']['input']>;
  amountBorrowed_not?: InputMaybe<Scalars['BigInt']['input']>;
  amountBorrowed_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  amountBorrowed_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  amountBorrowed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  amountBorrowed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  amountBorrowed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  amountBorrowed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  loansResolvedCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansResolvedCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansResolvedCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansResolvedCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansResolvedCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansResolvedCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansResolvedCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansResolvedCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  netLiquidityIssued?: InputMaybe<Scalars['BigInt']['input']>;
  netLiquidityIssued_not?: InputMaybe<Scalars['BigInt']['input']>;
  netLiquidityIssued_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  netLiquidityIssued_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  netLiquidityIssued_gt?: InputMaybe<Scalars['BigInt']['input']>;
  netLiquidityIssued_lt?: InputMaybe<Scalars['BigInt']['input']>;
  netLiquidityIssued_gte?: InputMaybe<Scalars['BigInt']['input']>;
  netLiquidityIssued_lte?: InputMaybe<Scalars['BigInt']['input']>;
  loansDefaultedCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansDefaultedCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansDefaultedCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansDefaultedCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansDefaultedCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansDefaultedCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansDefaultedCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansDefaultedCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
};

export type LendingDeskStatus =
  | 'Active'
  | 'Frozen';

export type LoanNftCollectionFilter = {
  id?: InputMaybe<Scalars['String']['input']>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_gt?: InputMaybe<Scalars['String']['input']>;
  id_lt?: InputMaybe<Scalars['String']['input']>;
  id_gte?: InputMaybe<Scalars['String']['input']>;
  id_lte?: InputMaybe<Scalars['String']['input']>;
  erc20Id?: InputMaybe<Scalars['String']['input']>;
  erc20Id_not?: InputMaybe<Scalars['String']['input']>;
  erc20Id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  erc20Id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  erc20Id_gt?: InputMaybe<Scalars['String']['input']>;
  erc20Id_lt?: InputMaybe<Scalars['String']['input']>;
  erc20Id_gte?: InputMaybe<Scalars['String']['input']>;
  erc20Id_lte?: InputMaybe<Scalars['String']['input']>;
  activeLoanConfigsCount?: InputMaybe<Scalars['BigInt']['input']>;
  activeLoanConfigsCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  activeLoanConfigsCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  activeLoanConfigsCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  activeLoanConfigsCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  activeLoanConfigsCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  activeLoanConfigsCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  activeLoanConfigsCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
};

export type LendingDeskPage = {
  items: Array<LendingDesk>;
  pageInfo: PageInfo;
};

export type LendingDesk = {
  id: Scalars['String']['output'];
  erc20: Erc20;
  loans?: Maybe<LoanPage>;
  owner: User;
  status: LendingDeskStatus;
  balance: Scalars['BigInt']['output'];
  erc20Id: Scalars['String']['output'];
  ownerId: Scalars['String']['output'];
  netProfit: Scalars['BigInt']['output'];
  loansCount: Scalars['BigInt']['output'];
  loanConfigs?: Maybe<LoanConfigPage>;
  amountBorrowed: Scalars['BigInt']['output'];
  loansResolvedCount: Scalars['BigInt']['output'];
  netLiquidityIssued: Scalars['BigInt']['output'];
  loansDefaultedCount: Scalars['BigInt']['output'];
};


export type LendingDeskloansArgs = {
  where?: InputMaybe<LoanFilter>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type LendingDeskloanConfigsArgs = {
  where?: InputMaybe<LoanConfigFilter>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Erc20 = {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  symbol: Scalars['String']['output'];
  decimals: Scalars['Int']['output'];
  lendingDesks?: Maybe<LendingDeskPage>;
  nftCollections?: Maybe<NftCollectionErc20Page>;
};


export type Erc20lendingDesksArgs = {
  where?: InputMaybe<LendingDeskFilter>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type Erc20nftCollectionsArgs = {
  where?: InputMaybe<NftCollectionErc20Filter1>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type LendingDeskFilter = {
  AND?: InputMaybe<Array<InputMaybe<LendingDeskFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LendingDeskFilter>>>;
  id?: InputMaybe<Scalars['String']['input']>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_contains?: InputMaybe<Scalars['String']['input']>;
  id_not_contains?: InputMaybe<Scalars['String']['input']>;
  id_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_ends_with?: InputMaybe<Scalars['String']['input']>;
  id_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  erc20_?: InputMaybe<LendingDeskErc20Filter>;
  loans_?: InputMaybe<LendingDeskLoanFilter>;
  owner_?: InputMaybe<LendingDeskUserFilter>;
  status?: InputMaybe<LendingDeskStatus>;
  status_not?: InputMaybe<LendingDeskStatus>;
  status_in?: InputMaybe<Array<InputMaybe<LendingDeskStatus>>>;
  status_not_in?: InputMaybe<Array<InputMaybe<LendingDeskStatus>>>;
  balance?: InputMaybe<Scalars['BigInt']['input']>;
  balance_not?: InputMaybe<Scalars['BigInt']['input']>;
  balance_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  balance_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  balance_gt?: InputMaybe<Scalars['BigInt']['input']>;
  balance_lt?: InputMaybe<Scalars['BigInt']['input']>;
  balance_gte?: InputMaybe<Scalars['BigInt']['input']>;
  balance_lte?: InputMaybe<Scalars['BigInt']['input']>;
  erc20Id?: InputMaybe<Scalars['String']['input']>;
  erc20Id_not?: InputMaybe<Scalars['String']['input']>;
  erc20Id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  erc20Id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  erc20Id_gt?: InputMaybe<Scalars['String']['input']>;
  erc20Id_lt?: InputMaybe<Scalars['String']['input']>;
  erc20Id_gte?: InputMaybe<Scalars['String']['input']>;
  erc20Id_lte?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['String']['input']>;
  ownerId_not?: InputMaybe<Scalars['String']['input']>;
  ownerId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ownerId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ownerId_gt?: InputMaybe<Scalars['String']['input']>;
  ownerId_lt?: InputMaybe<Scalars['String']['input']>;
  ownerId_gte?: InputMaybe<Scalars['String']['input']>;
  ownerId_lte?: InputMaybe<Scalars['String']['input']>;
  netProfit?: InputMaybe<Scalars['BigInt']['input']>;
  netProfit_not?: InputMaybe<Scalars['BigInt']['input']>;
  netProfit_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  netProfit_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  netProfit_gt?: InputMaybe<Scalars['BigInt']['input']>;
  netProfit_lt?: InputMaybe<Scalars['BigInt']['input']>;
  netProfit_gte?: InputMaybe<Scalars['BigInt']['input']>;
  netProfit_lte?: InputMaybe<Scalars['BigInt']['input']>;
  loansCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  loanConfigs_?: InputMaybe<LendingDeskLoanConfigFilter>;
  amountBorrowed?: InputMaybe<Scalars['BigInt']['input']>;
  amountBorrowed_not?: InputMaybe<Scalars['BigInt']['input']>;
  amountBorrowed_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  amountBorrowed_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  amountBorrowed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  amountBorrowed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  amountBorrowed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  amountBorrowed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  loansResolvedCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansResolvedCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansResolvedCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansResolvedCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansResolvedCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansResolvedCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansResolvedCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansResolvedCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  netLiquidityIssued?: InputMaybe<Scalars['BigInt']['input']>;
  netLiquidityIssued_not?: InputMaybe<Scalars['BigInt']['input']>;
  netLiquidityIssued_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  netLiquidityIssued_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  netLiquidityIssued_gt?: InputMaybe<Scalars['BigInt']['input']>;
  netLiquidityIssued_lt?: InputMaybe<Scalars['BigInt']['input']>;
  netLiquidityIssued_gte?: InputMaybe<Scalars['BigInt']['input']>;
  netLiquidityIssued_lte?: InputMaybe<Scalars['BigInt']['input']>;
  loansDefaultedCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansDefaultedCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansDefaultedCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansDefaultedCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansDefaultedCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansDefaultedCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansDefaultedCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansDefaultedCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
};

export type LendingDeskErc20Filter = {
  id?: InputMaybe<Scalars['String']['input']>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_gt?: InputMaybe<Scalars['String']['input']>;
  id_lt?: InputMaybe<Scalars['String']['input']>;
  id_gte?: InputMaybe<Scalars['String']['input']>;
  id_lte?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  symbol_not?: InputMaybe<Scalars['String']['input']>;
  symbol_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  symbol_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  symbol_contains?: InputMaybe<Scalars['String']['input']>;
  symbol_not_contains?: InputMaybe<Scalars['String']['input']>;
  symbol_starts_with?: InputMaybe<Scalars['String']['input']>;
  symbol_ends_with?: InputMaybe<Scalars['String']['input']>;
  symbol_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  symbol_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  decimals?: InputMaybe<Scalars['Int']['input']>;
  decimals_not?: InputMaybe<Scalars['Int']['input']>;
  decimals_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  decimals_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  decimals_gt?: InputMaybe<Scalars['Int']['input']>;
  decimals_lt?: InputMaybe<Scalars['Int']['input']>;
  decimals_gte?: InputMaybe<Scalars['Int']['input']>;
  decimals_lte?: InputMaybe<Scalars['Int']['input']>;
};

export type LendingDeskLoanFilter = {
  id?: InputMaybe<Scalars['String']['input']>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_contains?: InputMaybe<Scalars['String']['input']>;
  id_not_contains?: InputMaybe<Scalars['String']['input']>;
  id_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_ends_with?: InputMaybe<Scalars['String']['input']>;
  id_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  nftId?: InputMaybe<Scalars['BigInt']['input']>;
  nftId_not?: InputMaybe<Scalars['BigInt']['input']>;
  nftId_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  nftId_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  nftId_gt?: InputMaybe<Scalars['BigInt']['input']>;
  nftId_lt?: InputMaybe<Scalars['BigInt']['input']>;
  nftId_gte?: InputMaybe<Scalars['BigInt']['input']>;
  nftId_lte?: InputMaybe<Scalars['BigInt']['input']>;
  amount?: InputMaybe<Scalars['BigInt']['input']>;
  amount_not?: InputMaybe<Scalars['BigInt']['input']>;
  amount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  amount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  amount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  status?: InputMaybe<LoanStatus>;
  status_not?: InputMaybe<LoanStatus>;
  status_in?: InputMaybe<Array<InputMaybe<LoanStatus>>>;
  status_not_in?: InputMaybe<Array<InputMaybe<LoanStatus>>>;
  txHash?: InputMaybe<Scalars['String']['input']>;
  txHash_not?: InputMaybe<Scalars['String']['input']>;
  txHash_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  txHash_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  txHash_gt?: InputMaybe<Scalars['String']['input']>;
  txHash_lt?: InputMaybe<Scalars['String']['input']>;
  txHash_gte?: InputMaybe<Scalars['String']['input']>;
  txHash_lte?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['BigInt']['input']>;
  duration_not?: InputMaybe<Scalars['BigInt']['input']>;
  duration_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  duration_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  duration_gt?: InputMaybe<Scalars['BigInt']['input']>;
  duration_lt?: InputMaybe<Scalars['BigInt']['input']>;
  duration_gte?: InputMaybe<Scalars['BigInt']['input']>;
  duration_lte?: InputMaybe<Scalars['BigInt']['input']>;
  interest?: InputMaybe<Scalars['BigInt']['input']>;
  interest_not?: InputMaybe<Scalars['BigInt']['input']>;
  interest_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  interest_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  interest_gt?: InputMaybe<Scalars['BigInt']['input']>;
  interest_lt?: InputMaybe<Scalars['BigInt']['input']>;
  interest_gte?: InputMaybe<Scalars['BigInt']['input']>;
  interest_lte?: InputMaybe<Scalars['BigInt']['input']>;
  lenderId?: InputMaybe<Scalars['String']['input']>;
  lenderId_not?: InputMaybe<Scalars['String']['input']>;
  lenderId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lenderId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lenderId_gt?: InputMaybe<Scalars['String']['input']>;
  lenderId_lt?: InputMaybe<Scalars['String']['input']>;
  lenderId_gte?: InputMaybe<Scalars['String']['input']>;
  lenderId_lte?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['BigInt']['input']>;
  startTime_not?: InputMaybe<Scalars['BigInt']['input']>;
  startTime_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  startTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  startTime_gt?: InputMaybe<Scalars['BigInt']['input']>;
  startTime_lt?: InputMaybe<Scalars['BigInt']['input']>;
  startTime_gte?: InputMaybe<Scalars['BigInt']['input']>;
  startTime_lte?: InputMaybe<Scalars['BigInt']['input']>;
  borrowerId?: InputMaybe<Scalars['String']['input']>;
  borrowerId_not?: InputMaybe<Scalars['String']['input']>;
  borrowerId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  borrowerId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  borrowerId_gt?: InputMaybe<Scalars['String']['input']>;
  borrowerId_lt?: InputMaybe<Scalars['String']['input']>;
  borrowerId_gte?: InputMaybe<Scalars['String']['input']>;
  borrowerId_lte?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_not?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lendingDeskId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lendingDeskId_contains?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_not_contains?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_starts_with?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_ends_with?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  amountPaidBack?: InputMaybe<Scalars['BigInt']['input']>;
  amountPaidBack_not?: InputMaybe<Scalars['BigInt']['input']>;
  amountPaidBack_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  amountPaidBack_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  amountPaidBack_gt?: InputMaybe<Scalars['BigInt']['input']>;
  amountPaidBack_lt?: InputMaybe<Scalars['BigInt']['input']>;
  amountPaidBack_gte?: InputMaybe<Scalars['BigInt']['input']>;
  amountPaidBack_lte?: InputMaybe<Scalars['BigInt']['input']>;
  nftCollectionId?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_not?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nftCollectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nftCollectionId_gt?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_lt?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_gte?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_lte?: InputMaybe<Scalars['String']['input']>;
};

export type LendingDeskUserFilter = {
  id?: InputMaybe<Scalars['String']['input']>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_gt?: InputMaybe<Scalars['String']['input']>;
  id_lt?: InputMaybe<Scalars['String']['input']>;
  id_gte?: InputMaybe<Scalars['String']['input']>;
  id_lte?: InputMaybe<Scalars['String']['input']>;
  loansTakenCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansTakenCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansTakenCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansIssuedCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansIssuedCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenResolvedCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenResolvedCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenResolvedCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansTakenResolvedCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansTakenResolvedCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenResolvedCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenResolvedCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenResolvedCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedResolvedCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedResolvedCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedResolvedCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansIssuedResolvedCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansIssuedResolvedCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedResolvedCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedResolvedCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedResolvedCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenDefaultedCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenDefaultedCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenDefaultedCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansTakenDefaultedCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansTakenDefaultedCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenDefaultedCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenDefaultedCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenDefaultedCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedDefaultedCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedDefaultedCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedDefaultedCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansIssuedDefaultedCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansIssuedDefaultedCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedDefaultedCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedDefaultedCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedDefaultedCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
};

export type LendingDeskLoanConfigFilter = {
  id?: InputMaybe<Scalars['String']['input']>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_contains?: InputMaybe<Scalars['String']['input']>;
  id_not_contains?: InputMaybe<Scalars['String']['input']>;
  id_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_ends_with?: InputMaybe<Scalars['String']['input']>;
  id_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  active_not?: InputMaybe<Scalars['Boolean']['input']>;
  active_in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  active_not_in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  maxAmount?: InputMaybe<Scalars['BigInt']['input']>;
  maxAmount_not?: InputMaybe<Scalars['BigInt']['input']>;
  maxAmount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  maxAmount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  maxAmount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  maxAmount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  maxAmount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  maxAmount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  minAmount?: InputMaybe<Scalars['BigInt']['input']>;
  minAmount_not?: InputMaybe<Scalars['BigInt']['input']>;
  minAmount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  minAmount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  minAmount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  minAmount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  minAmount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  minAmount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  maxDuration?: InputMaybe<Scalars['BigInt']['input']>;
  maxDuration_not?: InputMaybe<Scalars['BigInt']['input']>;
  maxDuration_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  maxDuration_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  maxDuration_gt?: InputMaybe<Scalars['BigInt']['input']>;
  maxDuration_lt?: InputMaybe<Scalars['BigInt']['input']>;
  maxDuration_gte?: InputMaybe<Scalars['BigInt']['input']>;
  maxDuration_lte?: InputMaybe<Scalars['BigInt']['input']>;
  maxInterest?: InputMaybe<Scalars['BigInt']['input']>;
  maxInterest_not?: InputMaybe<Scalars['BigInt']['input']>;
  maxInterest_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  maxInterest_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  maxInterest_gt?: InputMaybe<Scalars['BigInt']['input']>;
  maxInterest_lt?: InputMaybe<Scalars['BigInt']['input']>;
  maxInterest_gte?: InputMaybe<Scalars['BigInt']['input']>;
  maxInterest_lte?: InputMaybe<Scalars['BigInt']['input']>;
  minDuration?: InputMaybe<Scalars['BigInt']['input']>;
  minDuration_not?: InputMaybe<Scalars['BigInt']['input']>;
  minDuration_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  minDuration_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  minDuration_gt?: InputMaybe<Scalars['BigInt']['input']>;
  minDuration_lt?: InputMaybe<Scalars['BigInt']['input']>;
  minDuration_gte?: InputMaybe<Scalars['BigInt']['input']>;
  minDuration_lte?: InputMaybe<Scalars['BigInt']['input']>;
  minInterest?: InputMaybe<Scalars['BigInt']['input']>;
  minInterest_not?: InputMaybe<Scalars['BigInt']['input']>;
  minInterest_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  minInterest_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  minInterest_gt?: InputMaybe<Scalars['BigInt']['input']>;
  minInterest_lt?: InputMaybe<Scalars['BigInt']['input']>;
  minInterest_gte?: InputMaybe<Scalars['BigInt']['input']>;
  minInterest_lte?: InputMaybe<Scalars['BigInt']['input']>;
  lendingDeskId?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_not?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lendingDeskId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lendingDeskId_contains?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_not_contains?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_starts_with?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_ends_with?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_not?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nftCollectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nftCollectionId_gt?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_lt?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_gte?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_lte?: InputMaybe<Scalars['String']['input']>;
  nftCollectionIsErc1155?: InputMaybe<Scalars['Boolean']['input']>;
  nftCollectionIsErc1155_not?: InputMaybe<Scalars['Boolean']['input']>;
  nftCollectionIsErc1155_in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  nftCollectionIsErc1155_not_in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
};

export type NftCollectionErc20Page = {
  items: Array<NftCollectionErc20>;
  pageInfo: PageInfo;
};

export type NftCollectionErc20 = {
  id: Scalars['String']['output'];
  erc20: Erc20;
  erc20Id: Scalars['String']['output'];
  nftCollection: NftCollection;
  nftCollectionId: Scalars['String']['output'];
};

export type NftCollection = {
  id: Scalars['String']['output'];
  erc20: Erc20;
  erc20s?: Maybe<NftCollectionErc20Page>;
  erc20Id: Scalars['String']['output'];
  loanConfigs?: Maybe<LoanConfigPage>;
  activeLoanConfigsCount: Scalars['BigInt']['output'];
};


export type NftCollectionerc20sArgs = {
  where?: InputMaybe<NftCollectionErc20Filter1>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type NftCollectionloanConfigsArgs = {
  where?: InputMaybe<LoanConfigFilter>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type NftCollectionErc20Filter1 = {
  AND?: InputMaybe<Array<InputMaybe<NftCollectionErc20Filter1>>>;
  OR?: InputMaybe<Array<InputMaybe<NftCollectionErc20Filter1>>>;
  id?: InputMaybe<Scalars['String']['input']>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_contains?: InputMaybe<Scalars['String']['input']>;
  id_not_contains?: InputMaybe<Scalars['String']['input']>;
  id_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_ends_with?: InputMaybe<Scalars['String']['input']>;
  id_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  erc20_?: InputMaybe<NftCollectionErc20Erc20Filter>;
  erc20Id?: InputMaybe<Scalars['String']['input']>;
  erc20Id_not?: InputMaybe<Scalars['String']['input']>;
  erc20Id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  erc20Id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  erc20Id_gt?: InputMaybe<Scalars['String']['input']>;
  erc20Id_lt?: InputMaybe<Scalars['String']['input']>;
  erc20Id_gte?: InputMaybe<Scalars['String']['input']>;
  erc20Id_lte?: InputMaybe<Scalars['String']['input']>;
  nftCollection_?: InputMaybe<NftCollectionErc20NftCollectionFilter>;
  nftCollectionId?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_not?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nftCollectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nftCollectionId_gt?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_lt?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_gte?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_lte?: InputMaybe<Scalars['String']['input']>;
};

export type NftCollectionErc20Erc20Filter = {
  id?: InputMaybe<Scalars['String']['input']>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_gt?: InputMaybe<Scalars['String']['input']>;
  id_lt?: InputMaybe<Scalars['String']['input']>;
  id_gte?: InputMaybe<Scalars['String']['input']>;
  id_lte?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  symbol_not?: InputMaybe<Scalars['String']['input']>;
  symbol_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  symbol_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  symbol_contains?: InputMaybe<Scalars['String']['input']>;
  symbol_not_contains?: InputMaybe<Scalars['String']['input']>;
  symbol_starts_with?: InputMaybe<Scalars['String']['input']>;
  symbol_ends_with?: InputMaybe<Scalars['String']['input']>;
  symbol_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  symbol_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  decimals?: InputMaybe<Scalars['Int']['input']>;
  decimals_not?: InputMaybe<Scalars['Int']['input']>;
  decimals_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  decimals_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  decimals_gt?: InputMaybe<Scalars['Int']['input']>;
  decimals_lt?: InputMaybe<Scalars['Int']['input']>;
  decimals_gte?: InputMaybe<Scalars['Int']['input']>;
  decimals_lte?: InputMaybe<Scalars['Int']['input']>;
};

export type NftCollectionErc20NftCollectionFilter = {
  id?: InputMaybe<Scalars['String']['input']>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_gt?: InputMaybe<Scalars['String']['input']>;
  id_lt?: InputMaybe<Scalars['String']['input']>;
  id_gte?: InputMaybe<Scalars['String']['input']>;
  id_lte?: InputMaybe<Scalars['String']['input']>;
  erc20Id?: InputMaybe<Scalars['String']['input']>;
  erc20Id_not?: InputMaybe<Scalars['String']['input']>;
  erc20Id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  erc20Id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  erc20Id_gt?: InputMaybe<Scalars['String']['input']>;
  erc20Id_lt?: InputMaybe<Scalars['String']['input']>;
  erc20Id_gte?: InputMaybe<Scalars['String']['input']>;
  erc20Id_lte?: InputMaybe<Scalars['String']['input']>;
  activeLoanConfigsCount?: InputMaybe<Scalars['BigInt']['input']>;
  activeLoanConfigsCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  activeLoanConfigsCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  activeLoanConfigsCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  activeLoanConfigsCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  activeLoanConfigsCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  activeLoanConfigsCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  activeLoanConfigsCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
};

export type LoanConfigPage = {
  items: Array<LoanConfig>;
  pageInfo: PageInfo;
};

export type LoanConfig = {
  id: Scalars['String']['output'];
  active: Scalars['Boolean']['output'];
  maxAmount: Scalars['BigInt']['output'];
  minAmount: Scalars['BigInt']['output'];
  lendingDesk: LendingDesk;
  maxDuration: Scalars['BigInt']['output'];
  maxInterest: Scalars['BigInt']['output'];
  minDuration: Scalars['BigInt']['output'];
  minInterest: Scalars['BigInt']['output'];
  lendingDeskId: Scalars['String']['output'];
  nftCollection: NftCollection;
  nftCollectionId: Scalars['String']['output'];
  nftCollectionIsErc1155: Scalars['Boolean']['output'];
};

export type LoanConfigFilter = {
  AND?: InputMaybe<Array<InputMaybe<LoanConfigFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LoanConfigFilter>>>;
  id?: InputMaybe<Scalars['String']['input']>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_contains?: InputMaybe<Scalars['String']['input']>;
  id_not_contains?: InputMaybe<Scalars['String']['input']>;
  id_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_ends_with?: InputMaybe<Scalars['String']['input']>;
  id_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  active_not?: InputMaybe<Scalars['Boolean']['input']>;
  active_in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  active_not_in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  maxAmount?: InputMaybe<Scalars['BigInt']['input']>;
  maxAmount_not?: InputMaybe<Scalars['BigInt']['input']>;
  maxAmount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  maxAmount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  maxAmount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  maxAmount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  maxAmount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  maxAmount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  minAmount?: InputMaybe<Scalars['BigInt']['input']>;
  minAmount_not?: InputMaybe<Scalars['BigInt']['input']>;
  minAmount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  minAmount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  minAmount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  minAmount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  minAmount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  minAmount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  lendingDesk_?: InputMaybe<LoanConfigLendingDeskFilter>;
  maxDuration?: InputMaybe<Scalars['BigInt']['input']>;
  maxDuration_not?: InputMaybe<Scalars['BigInt']['input']>;
  maxDuration_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  maxDuration_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  maxDuration_gt?: InputMaybe<Scalars['BigInt']['input']>;
  maxDuration_lt?: InputMaybe<Scalars['BigInt']['input']>;
  maxDuration_gte?: InputMaybe<Scalars['BigInt']['input']>;
  maxDuration_lte?: InputMaybe<Scalars['BigInt']['input']>;
  maxInterest?: InputMaybe<Scalars['BigInt']['input']>;
  maxInterest_not?: InputMaybe<Scalars['BigInt']['input']>;
  maxInterest_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  maxInterest_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  maxInterest_gt?: InputMaybe<Scalars['BigInt']['input']>;
  maxInterest_lt?: InputMaybe<Scalars['BigInt']['input']>;
  maxInterest_gte?: InputMaybe<Scalars['BigInt']['input']>;
  maxInterest_lte?: InputMaybe<Scalars['BigInt']['input']>;
  minDuration?: InputMaybe<Scalars['BigInt']['input']>;
  minDuration_not?: InputMaybe<Scalars['BigInt']['input']>;
  minDuration_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  minDuration_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  minDuration_gt?: InputMaybe<Scalars['BigInt']['input']>;
  minDuration_lt?: InputMaybe<Scalars['BigInt']['input']>;
  minDuration_gte?: InputMaybe<Scalars['BigInt']['input']>;
  minDuration_lte?: InputMaybe<Scalars['BigInt']['input']>;
  minInterest?: InputMaybe<Scalars['BigInt']['input']>;
  minInterest_not?: InputMaybe<Scalars['BigInt']['input']>;
  minInterest_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  minInterest_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  minInterest_gt?: InputMaybe<Scalars['BigInt']['input']>;
  minInterest_lt?: InputMaybe<Scalars['BigInt']['input']>;
  minInterest_gte?: InputMaybe<Scalars['BigInt']['input']>;
  minInterest_lte?: InputMaybe<Scalars['BigInt']['input']>;
  lendingDeskId?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_not?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lendingDeskId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lendingDeskId_contains?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_not_contains?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_starts_with?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_ends_with?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  nftCollection_?: InputMaybe<LoanConfigNftCollectionFilter>;
  nftCollectionId?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_not?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nftCollectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nftCollectionId_gt?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_lt?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_gte?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_lte?: InputMaybe<Scalars['String']['input']>;
  nftCollectionIsErc1155?: InputMaybe<Scalars['Boolean']['input']>;
  nftCollectionIsErc1155_not?: InputMaybe<Scalars['Boolean']['input']>;
  nftCollectionIsErc1155_in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  nftCollectionIsErc1155_not_in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
};

export type LoanConfigLendingDeskFilter = {
  id?: InputMaybe<Scalars['String']['input']>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_contains?: InputMaybe<Scalars['String']['input']>;
  id_not_contains?: InputMaybe<Scalars['String']['input']>;
  id_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_ends_with?: InputMaybe<Scalars['String']['input']>;
  id_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<LendingDeskStatus>;
  status_not?: InputMaybe<LendingDeskStatus>;
  status_in?: InputMaybe<Array<InputMaybe<LendingDeskStatus>>>;
  status_not_in?: InputMaybe<Array<InputMaybe<LendingDeskStatus>>>;
  balance?: InputMaybe<Scalars['BigInt']['input']>;
  balance_not?: InputMaybe<Scalars['BigInt']['input']>;
  balance_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  balance_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  balance_gt?: InputMaybe<Scalars['BigInt']['input']>;
  balance_lt?: InputMaybe<Scalars['BigInt']['input']>;
  balance_gte?: InputMaybe<Scalars['BigInt']['input']>;
  balance_lte?: InputMaybe<Scalars['BigInt']['input']>;
  erc20Id?: InputMaybe<Scalars['String']['input']>;
  erc20Id_not?: InputMaybe<Scalars['String']['input']>;
  erc20Id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  erc20Id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  erc20Id_gt?: InputMaybe<Scalars['String']['input']>;
  erc20Id_lt?: InputMaybe<Scalars['String']['input']>;
  erc20Id_gte?: InputMaybe<Scalars['String']['input']>;
  erc20Id_lte?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['String']['input']>;
  ownerId_not?: InputMaybe<Scalars['String']['input']>;
  ownerId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ownerId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ownerId_gt?: InputMaybe<Scalars['String']['input']>;
  ownerId_lt?: InputMaybe<Scalars['String']['input']>;
  ownerId_gte?: InputMaybe<Scalars['String']['input']>;
  ownerId_lte?: InputMaybe<Scalars['String']['input']>;
  netProfit?: InputMaybe<Scalars['BigInt']['input']>;
  netProfit_not?: InputMaybe<Scalars['BigInt']['input']>;
  netProfit_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  netProfit_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  netProfit_gt?: InputMaybe<Scalars['BigInt']['input']>;
  netProfit_lt?: InputMaybe<Scalars['BigInt']['input']>;
  netProfit_gte?: InputMaybe<Scalars['BigInt']['input']>;
  netProfit_lte?: InputMaybe<Scalars['BigInt']['input']>;
  loansCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  amountBorrowed?: InputMaybe<Scalars['BigInt']['input']>;
  amountBorrowed_not?: InputMaybe<Scalars['BigInt']['input']>;
  amountBorrowed_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  amountBorrowed_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  amountBorrowed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  amountBorrowed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  amountBorrowed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  amountBorrowed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  loansResolvedCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansResolvedCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansResolvedCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansResolvedCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansResolvedCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansResolvedCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansResolvedCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansResolvedCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  netLiquidityIssued?: InputMaybe<Scalars['BigInt']['input']>;
  netLiquidityIssued_not?: InputMaybe<Scalars['BigInt']['input']>;
  netLiquidityIssued_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  netLiquidityIssued_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  netLiquidityIssued_gt?: InputMaybe<Scalars['BigInt']['input']>;
  netLiquidityIssued_lt?: InputMaybe<Scalars['BigInt']['input']>;
  netLiquidityIssued_gte?: InputMaybe<Scalars['BigInt']['input']>;
  netLiquidityIssued_lte?: InputMaybe<Scalars['BigInt']['input']>;
  loansDefaultedCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansDefaultedCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansDefaultedCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansDefaultedCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansDefaultedCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansDefaultedCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansDefaultedCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansDefaultedCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
};

export type LoanConfigNftCollectionFilter = {
  id?: InputMaybe<Scalars['String']['input']>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_gt?: InputMaybe<Scalars['String']['input']>;
  id_lt?: InputMaybe<Scalars['String']['input']>;
  id_gte?: InputMaybe<Scalars['String']['input']>;
  id_lte?: InputMaybe<Scalars['String']['input']>;
  erc20Id?: InputMaybe<Scalars['String']['input']>;
  erc20Id_not?: InputMaybe<Scalars['String']['input']>;
  erc20Id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  erc20Id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  erc20Id_gt?: InputMaybe<Scalars['String']['input']>;
  erc20Id_lt?: InputMaybe<Scalars['String']['input']>;
  erc20Id_gte?: InputMaybe<Scalars['String']['input']>;
  erc20Id_lte?: InputMaybe<Scalars['String']['input']>;
  activeLoanConfigsCount?: InputMaybe<Scalars['BigInt']['input']>;
  activeLoanConfigsCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  activeLoanConfigsCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  activeLoanConfigsCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  activeLoanConfigsCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  activeLoanConfigsCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  activeLoanConfigsCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  activeLoanConfigsCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
};

export type UserPage = {
  items: Array<User>;
  pageInfo: PageInfo;
};

export type UserFilter = {
  AND?: InputMaybe<Array<InputMaybe<UserFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<UserFilter>>>;
  id?: InputMaybe<Scalars['String']['input']>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_gt?: InputMaybe<Scalars['String']['input']>;
  id_lt?: InputMaybe<Scalars['String']['input']>;
  id_gte?: InputMaybe<Scalars['String']['input']>;
  id_lte?: InputMaybe<Scalars['String']['input']>;
  loansTaken_?: InputMaybe<UserLoanFilter>;
  loansIssued_?: InputMaybe<UserLoanFilter1>;
  lendingDesks_?: InputMaybe<UserLendingDeskFilter>;
  loansTakenCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansTakenCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansTakenCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansIssuedCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansIssuedCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenResolvedCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenResolvedCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenResolvedCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansTakenResolvedCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansTakenResolvedCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenResolvedCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenResolvedCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenResolvedCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedResolvedCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedResolvedCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedResolvedCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansIssuedResolvedCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansIssuedResolvedCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedResolvedCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedResolvedCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedResolvedCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenDefaultedCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenDefaultedCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenDefaultedCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansTakenDefaultedCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansTakenDefaultedCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenDefaultedCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenDefaultedCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansTakenDefaultedCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedDefaultedCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedDefaultedCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedDefaultedCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansIssuedDefaultedCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansIssuedDefaultedCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedDefaultedCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedDefaultedCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansIssuedDefaultedCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
};

export type UserLoanFilter = {
  id?: InputMaybe<Scalars['String']['input']>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_contains?: InputMaybe<Scalars['String']['input']>;
  id_not_contains?: InputMaybe<Scalars['String']['input']>;
  id_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_ends_with?: InputMaybe<Scalars['String']['input']>;
  id_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  nftId?: InputMaybe<Scalars['BigInt']['input']>;
  nftId_not?: InputMaybe<Scalars['BigInt']['input']>;
  nftId_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  nftId_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  nftId_gt?: InputMaybe<Scalars['BigInt']['input']>;
  nftId_lt?: InputMaybe<Scalars['BigInt']['input']>;
  nftId_gte?: InputMaybe<Scalars['BigInt']['input']>;
  nftId_lte?: InputMaybe<Scalars['BigInt']['input']>;
  amount?: InputMaybe<Scalars['BigInt']['input']>;
  amount_not?: InputMaybe<Scalars['BigInt']['input']>;
  amount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  amount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  amount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  status?: InputMaybe<LoanStatus>;
  status_not?: InputMaybe<LoanStatus>;
  status_in?: InputMaybe<Array<InputMaybe<LoanStatus>>>;
  status_not_in?: InputMaybe<Array<InputMaybe<LoanStatus>>>;
  txHash?: InputMaybe<Scalars['String']['input']>;
  txHash_not?: InputMaybe<Scalars['String']['input']>;
  txHash_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  txHash_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  txHash_gt?: InputMaybe<Scalars['String']['input']>;
  txHash_lt?: InputMaybe<Scalars['String']['input']>;
  txHash_gte?: InputMaybe<Scalars['String']['input']>;
  txHash_lte?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['BigInt']['input']>;
  duration_not?: InputMaybe<Scalars['BigInt']['input']>;
  duration_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  duration_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  duration_gt?: InputMaybe<Scalars['BigInt']['input']>;
  duration_lt?: InputMaybe<Scalars['BigInt']['input']>;
  duration_gte?: InputMaybe<Scalars['BigInt']['input']>;
  duration_lte?: InputMaybe<Scalars['BigInt']['input']>;
  interest?: InputMaybe<Scalars['BigInt']['input']>;
  interest_not?: InputMaybe<Scalars['BigInt']['input']>;
  interest_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  interest_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  interest_gt?: InputMaybe<Scalars['BigInt']['input']>;
  interest_lt?: InputMaybe<Scalars['BigInt']['input']>;
  interest_gte?: InputMaybe<Scalars['BigInt']['input']>;
  interest_lte?: InputMaybe<Scalars['BigInt']['input']>;
  lenderId?: InputMaybe<Scalars['String']['input']>;
  lenderId_not?: InputMaybe<Scalars['String']['input']>;
  lenderId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lenderId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lenderId_gt?: InputMaybe<Scalars['String']['input']>;
  lenderId_lt?: InputMaybe<Scalars['String']['input']>;
  lenderId_gte?: InputMaybe<Scalars['String']['input']>;
  lenderId_lte?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['BigInt']['input']>;
  startTime_not?: InputMaybe<Scalars['BigInt']['input']>;
  startTime_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  startTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  startTime_gt?: InputMaybe<Scalars['BigInt']['input']>;
  startTime_lt?: InputMaybe<Scalars['BigInt']['input']>;
  startTime_gte?: InputMaybe<Scalars['BigInt']['input']>;
  startTime_lte?: InputMaybe<Scalars['BigInt']['input']>;
  borrowerId?: InputMaybe<Scalars['String']['input']>;
  borrowerId_not?: InputMaybe<Scalars['String']['input']>;
  borrowerId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  borrowerId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  borrowerId_gt?: InputMaybe<Scalars['String']['input']>;
  borrowerId_lt?: InputMaybe<Scalars['String']['input']>;
  borrowerId_gte?: InputMaybe<Scalars['String']['input']>;
  borrowerId_lte?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_not?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lendingDeskId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lendingDeskId_contains?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_not_contains?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_starts_with?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_ends_with?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  amountPaidBack?: InputMaybe<Scalars['BigInt']['input']>;
  amountPaidBack_not?: InputMaybe<Scalars['BigInt']['input']>;
  amountPaidBack_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  amountPaidBack_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  amountPaidBack_gt?: InputMaybe<Scalars['BigInt']['input']>;
  amountPaidBack_lt?: InputMaybe<Scalars['BigInt']['input']>;
  amountPaidBack_gte?: InputMaybe<Scalars['BigInt']['input']>;
  amountPaidBack_lte?: InputMaybe<Scalars['BigInt']['input']>;
  nftCollectionId?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_not?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nftCollectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nftCollectionId_gt?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_lt?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_gte?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_lte?: InputMaybe<Scalars['String']['input']>;
};

export type UserLoanFilter1 = {
  id?: InputMaybe<Scalars['String']['input']>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_contains?: InputMaybe<Scalars['String']['input']>;
  id_not_contains?: InputMaybe<Scalars['String']['input']>;
  id_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_ends_with?: InputMaybe<Scalars['String']['input']>;
  id_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  nftId?: InputMaybe<Scalars['BigInt']['input']>;
  nftId_not?: InputMaybe<Scalars['BigInt']['input']>;
  nftId_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  nftId_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  nftId_gt?: InputMaybe<Scalars['BigInt']['input']>;
  nftId_lt?: InputMaybe<Scalars['BigInt']['input']>;
  nftId_gte?: InputMaybe<Scalars['BigInt']['input']>;
  nftId_lte?: InputMaybe<Scalars['BigInt']['input']>;
  amount?: InputMaybe<Scalars['BigInt']['input']>;
  amount_not?: InputMaybe<Scalars['BigInt']['input']>;
  amount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  amount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  amount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  status?: InputMaybe<LoanStatus>;
  status_not?: InputMaybe<LoanStatus>;
  status_in?: InputMaybe<Array<InputMaybe<LoanStatus>>>;
  status_not_in?: InputMaybe<Array<InputMaybe<LoanStatus>>>;
  txHash?: InputMaybe<Scalars['String']['input']>;
  txHash_not?: InputMaybe<Scalars['String']['input']>;
  txHash_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  txHash_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  txHash_gt?: InputMaybe<Scalars['String']['input']>;
  txHash_lt?: InputMaybe<Scalars['String']['input']>;
  txHash_gte?: InputMaybe<Scalars['String']['input']>;
  txHash_lte?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['BigInt']['input']>;
  duration_not?: InputMaybe<Scalars['BigInt']['input']>;
  duration_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  duration_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  duration_gt?: InputMaybe<Scalars['BigInt']['input']>;
  duration_lt?: InputMaybe<Scalars['BigInt']['input']>;
  duration_gte?: InputMaybe<Scalars['BigInt']['input']>;
  duration_lte?: InputMaybe<Scalars['BigInt']['input']>;
  interest?: InputMaybe<Scalars['BigInt']['input']>;
  interest_not?: InputMaybe<Scalars['BigInt']['input']>;
  interest_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  interest_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  interest_gt?: InputMaybe<Scalars['BigInt']['input']>;
  interest_lt?: InputMaybe<Scalars['BigInt']['input']>;
  interest_gte?: InputMaybe<Scalars['BigInt']['input']>;
  interest_lte?: InputMaybe<Scalars['BigInt']['input']>;
  lenderId?: InputMaybe<Scalars['String']['input']>;
  lenderId_not?: InputMaybe<Scalars['String']['input']>;
  lenderId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lenderId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lenderId_gt?: InputMaybe<Scalars['String']['input']>;
  lenderId_lt?: InputMaybe<Scalars['String']['input']>;
  lenderId_gte?: InputMaybe<Scalars['String']['input']>;
  lenderId_lte?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['BigInt']['input']>;
  startTime_not?: InputMaybe<Scalars['BigInt']['input']>;
  startTime_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  startTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  startTime_gt?: InputMaybe<Scalars['BigInt']['input']>;
  startTime_lt?: InputMaybe<Scalars['BigInt']['input']>;
  startTime_gte?: InputMaybe<Scalars['BigInt']['input']>;
  startTime_lte?: InputMaybe<Scalars['BigInt']['input']>;
  borrowerId?: InputMaybe<Scalars['String']['input']>;
  borrowerId_not?: InputMaybe<Scalars['String']['input']>;
  borrowerId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  borrowerId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  borrowerId_gt?: InputMaybe<Scalars['String']['input']>;
  borrowerId_lt?: InputMaybe<Scalars['String']['input']>;
  borrowerId_gte?: InputMaybe<Scalars['String']['input']>;
  borrowerId_lte?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_not?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lendingDeskId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lendingDeskId_contains?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_not_contains?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_starts_with?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_ends_with?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  amountPaidBack?: InputMaybe<Scalars['BigInt']['input']>;
  amountPaidBack_not?: InputMaybe<Scalars['BigInt']['input']>;
  amountPaidBack_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  amountPaidBack_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  amountPaidBack_gt?: InputMaybe<Scalars['BigInt']['input']>;
  amountPaidBack_lt?: InputMaybe<Scalars['BigInt']['input']>;
  amountPaidBack_gte?: InputMaybe<Scalars['BigInt']['input']>;
  amountPaidBack_lte?: InputMaybe<Scalars['BigInt']['input']>;
  nftCollectionId?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_not?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nftCollectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nftCollectionId_gt?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_lt?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_gte?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_lte?: InputMaybe<Scalars['String']['input']>;
};

export type UserLendingDeskFilter = {
  id?: InputMaybe<Scalars['String']['input']>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_contains?: InputMaybe<Scalars['String']['input']>;
  id_not_contains?: InputMaybe<Scalars['String']['input']>;
  id_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_ends_with?: InputMaybe<Scalars['String']['input']>;
  id_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<LendingDeskStatus>;
  status_not?: InputMaybe<LendingDeskStatus>;
  status_in?: InputMaybe<Array<InputMaybe<LendingDeskStatus>>>;
  status_not_in?: InputMaybe<Array<InputMaybe<LendingDeskStatus>>>;
  balance?: InputMaybe<Scalars['BigInt']['input']>;
  balance_not?: InputMaybe<Scalars['BigInt']['input']>;
  balance_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  balance_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  balance_gt?: InputMaybe<Scalars['BigInt']['input']>;
  balance_lt?: InputMaybe<Scalars['BigInt']['input']>;
  balance_gte?: InputMaybe<Scalars['BigInt']['input']>;
  balance_lte?: InputMaybe<Scalars['BigInt']['input']>;
  erc20Id?: InputMaybe<Scalars['String']['input']>;
  erc20Id_not?: InputMaybe<Scalars['String']['input']>;
  erc20Id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  erc20Id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  erc20Id_gt?: InputMaybe<Scalars['String']['input']>;
  erc20Id_lt?: InputMaybe<Scalars['String']['input']>;
  erc20Id_gte?: InputMaybe<Scalars['String']['input']>;
  erc20Id_lte?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['String']['input']>;
  ownerId_not?: InputMaybe<Scalars['String']['input']>;
  ownerId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ownerId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ownerId_gt?: InputMaybe<Scalars['String']['input']>;
  ownerId_lt?: InputMaybe<Scalars['String']['input']>;
  ownerId_gte?: InputMaybe<Scalars['String']['input']>;
  ownerId_lte?: InputMaybe<Scalars['String']['input']>;
  netProfit?: InputMaybe<Scalars['BigInt']['input']>;
  netProfit_not?: InputMaybe<Scalars['BigInt']['input']>;
  netProfit_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  netProfit_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  netProfit_gt?: InputMaybe<Scalars['BigInt']['input']>;
  netProfit_lt?: InputMaybe<Scalars['BigInt']['input']>;
  netProfit_gte?: InputMaybe<Scalars['BigInt']['input']>;
  netProfit_lte?: InputMaybe<Scalars['BigInt']['input']>;
  loansCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  amountBorrowed?: InputMaybe<Scalars['BigInt']['input']>;
  amountBorrowed_not?: InputMaybe<Scalars['BigInt']['input']>;
  amountBorrowed_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  amountBorrowed_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  amountBorrowed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  amountBorrowed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  amountBorrowed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  amountBorrowed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  loansResolvedCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansResolvedCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansResolvedCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansResolvedCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansResolvedCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansResolvedCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansResolvedCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansResolvedCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  netLiquidityIssued?: InputMaybe<Scalars['BigInt']['input']>;
  netLiquidityIssued_not?: InputMaybe<Scalars['BigInt']['input']>;
  netLiquidityIssued_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  netLiquidityIssued_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  netLiquidityIssued_gt?: InputMaybe<Scalars['BigInt']['input']>;
  netLiquidityIssued_lt?: InputMaybe<Scalars['BigInt']['input']>;
  netLiquidityIssued_gte?: InputMaybe<Scalars['BigInt']['input']>;
  netLiquidityIssued_lte?: InputMaybe<Scalars['BigInt']['input']>;
  loansDefaultedCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansDefaultedCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansDefaultedCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansDefaultedCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansDefaultedCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansDefaultedCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansDefaultedCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansDefaultedCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
};

export type Erc20Page = {
  items: Array<Erc20>;
  pageInfo: PageInfo;
};

export type Erc20Filter = {
  AND?: InputMaybe<Array<InputMaybe<Erc20Filter>>>;
  OR?: InputMaybe<Array<InputMaybe<Erc20Filter>>>;
  id?: InputMaybe<Scalars['String']['input']>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_gt?: InputMaybe<Scalars['String']['input']>;
  id_lt?: InputMaybe<Scalars['String']['input']>;
  id_gte?: InputMaybe<Scalars['String']['input']>;
  id_lte?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  symbol_not?: InputMaybe<Scalars['String']['input']>;
  symbol_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  symbol_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  symbol_contains?: InputMaybe<Scalars['String']['input']>;
  symbol_not_contains?: InputMaybe<Scalars['String']['input']>;
  symbol_starts_with?: InputMaybe<Scalars['String']['input']>;
  symbol_ends_with?: InputMaybe<Scalars['String']['input']>;
  symbol_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  symbol_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  decimals?: InputMaybe<Scalars['Int']['input']>;
  decimals_not?: InputMaybe<Scalars['Int']['input']>;
  decimals_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  decimals_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  decimals_gt?: InputMaybe<Scalars['Int']['input']>;
  decimals_lt?: InputMaybe<Scalars['Int']['input']>;
  decimals_gte?: InputMaybe<Scalars['Int']['input']>;
  decimals_lte?: InputMaybe<Scalars['Int']['input']>;
  lendingDesks_?: InputMaybe<Erc20LendingDeskFilter>;
  nftCollections_?: InputMaybe<Erc20NftCollectionErc20Filter>;
};

export type Erc20LendingDeskFilter = {
  id?: InputMaybe<Scalars['String']['input']>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_contains?: InputMaybe<Scalars['String']['input']>;
  id_not_contains?: InputMaybe<Scalars['String']['input']>;
  id_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_ends_with?: InputMaybe<Scalars['String']['input']>;
  id_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<LendingDeskStatus>;
  status_not?: InputMaybe<LendingDeskStatus>;
  status_in?: InputMaybe<Array<InputMaybe<LendingDeskStatus>>>;
  status_not_in?: InputMaybe<Array<InputMaybe<LendingDeskStatus>>>;
  balance?: InputMaybe<Scalars['BigInt']['input']>;
  balance_not?: InputMaybe<Scalars['BigInt']['input']>;
  balance_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  balance_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  balance_gt?: InputMaybe<Scalars['BigInt']['input']>;
  balance_lt?: InputMaybe<Scalars['BigInt']['input']>;
  balance_gte?: InputMaybe<Scalars['BigInt']['input']>;
  balance_lte?: InputMaybe<Scalars['BigInt']['input']>;
  erc20Id?: InputMaybe<Scalars['String']['input']>;
  erc20Id_not?: InputMaybe<Scalars['String']['input']>;
  erc20Id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  erc20Id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  erc20Id_gt?: InputMaybe<Scalars['String']['input']>;
  erc20Id_lt?: InputMaybe<Scalars['String']['input']>;
  erc20Id_gte?: InputMaybe<Scalars['String']['input']>;
  erc20Id_lte?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['String']['input']>;
  ownerId_not?: InputMaybe<Scalars['String']['input']>;
  ownerId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ownerId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ownerId_gt?: InputMaybe<Scalars['String']['input']>;
  ownerId_lt?: InputMaybe<Scalars['String']['input']>;
  ownerId_gte?: InputMaybe<Scalars['String']['input']>;
  ownerId_lte?: InputMaybe<Scalars['String']['input']>;
  netProfit?: InputMaybe<Scalars['BigInt']['input']>;
  netProfit_not?: InputMaybe<Scalars['BigInt']['input']>;
  netProfit_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  netProfit_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  netProfit_gt?: InputMaybe<Scalars['BigInt']['input']>;
  netProfit_lt?: InputMaybe<Scalars['BigInt']['input']>;
  netProfit_gte?: InputMaybe<Scalars['BigInt']['input']>;
  netProfit_lte?: InputMaybe<Scalars['BigInt']['input']>;
  loansCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  amountBorrowed?: InputMaybe<Scalars['BigInt']['input']>;
  amountBorrowed_not?: InputMaybe<Scalars['BigInt']['input']>;
  amountBorrowed_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  amountBorrowed_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  amountBorrowed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  amountBorrowed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  amountBorrowed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  amountBorrowed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  loansResolvedCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansResolvedCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansResolvedCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansResolvedCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansResolvedCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansResolvedCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansResolvedCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansResolvedCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  netLiquidityIssued?: InputMaybe<Scalars['BigInt']['input']>;
  netLiquidityIssued_not?: InputMaybe<Scalars['BigInt']['input']>;
  netLiquidityIssued_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  netLiquidityIssued_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  netLiquidityIssued_gt?: InputMaybe<Scalars['BigInt']['input']>;
  netLiquidityIssued_lt?: InputMaybe<Scalars['BigInt']['input']>;
  netLiquidityIssued_gte?: InputMaybe<Scalars['BigInt']['input']>;
  netLiquidityIssued_lte?: InputMaybe<Scalars['BigInt']['input']>;
  loansDefaultedCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansDefaultedCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansDefaultedCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansDefaultedCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansDefaultedCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansDefaultedCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansDefaultedCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansDefaultedCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
};

export type Erc20NftCollectionErc20Filter = {
  id?: InputMaybe<Scalars['String']['input']>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_contains?: InputMaybe<Scalars['String']['input']>;
  id_not_contains?: InputMaybe<Scalars['String']['input']>;
  id_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_ends_with?: InputMaybe<Scalars['String']['input']>;
  id_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  erc20Id?: InputMaybe<Scalars['String']['input']>;
  erc20Id_not?: InputMaybe<Scalars['String']['input']>;
  erc20Id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  erc20Id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  erc20Id_gt?: InputMaybe<Scalars['String']['input']>;
  erc20Id_lt?: InputMaybe<Scalars['String']['input']>;
  erc20Id_gte?: InputMaybe<Scalars['String']['input']>;
  erc20Id_lte?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_not?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nftCollectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nftCollectionId_gt?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_lt?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_gte?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_lte?: InputMaybe<Scalars['String']['input']>;
};

export type ProtocolInfo = {
  id: Scalars['String']['output'];
  owner: Scalars['String']['output'];
  paused: Scalars['Boolean']['output'];
  loansCount: Scalars['BigInt']['output'];
  erc20sCount: Scalars['BigInt']['output'];
  lendingKeys: Scalars['String']['output'];
  platformWallet: Scalars['String']['output'];
  obligationNotes: Scalars['String']['output'];
  lendingDesksCount: Scalars['BigInt']['output'];
  loanOriginationFee: Scalars['BigInt']['output'];
  nftCollectionsCount: Scalars['BigInt']['output'];
};

export type ProtocolInfoPage = {
  items: Array<ProtocolInfo>;
  pageInfo: PageInfo;
};

export type ProtocolInfoFilter = {
  AND?: InputMaybe<Array<InputMaybe<ProtocolInfoFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ProtocolInfoFilter>>>;
  id?: InputMaybe<Scalars['String']['input']>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_contains?: InputMaybe<Scalars['String']['input']>;
  id_not_contains?: InputMaybe<Scalars['String']['input']>;
  id_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_ends_with?: InputMaybe<Scalars['String']['input']>;
  id_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<Scalars['String']['input']>;
  owner_not?: InputMaybe<Scalars['String']['input']>;
  owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  owner_gt?: InputMaybe<Scalars['String']['input']>;
  owner_lt?: InputMaybe<Scalars['String']['input']>;
  owner_gte?: InputMaybe<Scalars['String']['input']>;
  owner_lte?: InputMaybe<Scalars['String']['input']>;
  paused?: InputMaybe<Scalars['Boolean']['input']>;
  paused_not?: InputMaybe<Scalars['Boolean']['input']>;
  paused_in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  paused_not_in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  loansCount?: InputMaybe<Scalars['BigInt']['input']>;
  loansCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  loansCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loansCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loansCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loansCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loansCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  erc20sCount?: InputMaybe<Scalars['BigInt']['input']>;
  erc20sCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  erc20sCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  erc20sCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  erc20sCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  erc20sCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  erc20sCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  erc20sCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  lendingKeys?: InputMaybe<Scalars['String']['input']>;
  lendingKeys_not?: InputMaybe<Scalars['String']['input']>;
  lendingKeys_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lendingKeys_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lendingKeys_gt?: InputMaybe<Scalars['String']['input']>;
  lendingKeys_lt?: InputMaybe<Scalars['String']['input']>;
  lendingKeys_gte?: InputMaybe<Scalars['String']['input']>;
  lendingKeys_lte?: InputMaybe<Scalars['String']['input']>;
  platformWallet?: InputMaybe<Scalars['String']['input']>;
  platformWallet_not?: InputMaybe<Scalars['String']['input']>;
  platformWallet_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  platformWallet_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  platformWallet_gt?: InputMaybe<Scalars['String']['input']>;
  platformWallet_lt?: InputMaybe<Scalars['String']['input']>;
  platformWallet_gte?: InputMaybe<Scalars['String']['input']>;
  platformWallet_lte?: InputMaybe<Scalars['String']['input']>;
  obligationNotes?: InputMaybe<Scalars['String']['input']>;
  obligationNotes_not?: InputMaybe<Scalars['String']['input']>;
  obligationNotes_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  obligationNotes_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  obligationNotes_gt?: InputMaybe<Scalars['String']['input']>;
  obligationNotes_lt?: InputMaybe<Scalars['String']['input']>;
  obligationNotes_gte?: InputMaybe<Scalars['String']['input']>;
  obligationNotes_lte?: InputMaybe<Scalars['String']['input']>;
  lendingDesksCount?: InputMaybe<Scalars['BigInt']['input']>;
  lendingDesksCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  lendingDesksCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  lendingDesksCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  lendingDesksCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  lendingDesksCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  lendingDesksCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  lendingDesksCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  loanOriginationFee?: InputMaybe<Scalars['BigInt']['input']>;
  loanOriginationFee_not?: InputMaybe<Scalars['BigInt']['input']>;
  loanOriginationFee_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loanOriginationFee_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  loanOriginationFee_gt?: InputMaybe<Scalars['BigInt']['input']>;
  loanOriginationFee_lt?: InputMaybe<Scalars['BigInt']['input']>;
  loanOriginationFee_gte?: InputMaybe<Scalars['BigInt']['input']>;
  loanOriginationFee_lte?: InputMaybe<Scalars['BigInt']['input']>;
  nftCollectionsCount?: InputMaybe<Scalars['BigInt']['input']>;
  nftCollectionsCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  nftCollectionsCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  nftCollectionsCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  nftCollectionsCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  nftCollectionsCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  nftCollectionsCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  nftCollectionsCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
};

export type NftCollectionPage = {
  items: Array<NftCollection>;
  pageInfo: PageInfo;
};

export type NftCollectionFilter = {
  AND?: InputMaybe<Array<InputMaybe<NftCollectionFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<NftCollectionFilter>>>;
  id?: InputMaybe<Scalars['String']['input']>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_gt?: InputMaybe<Scalars['String']['input']>;
  id_lt?: InputMaybe<Scalars['String']['input']>;
  id_gte?: InputMaybe<Scalars['String']['input']>;
  id_lte?: InputMaybe<Scalars['String']['input']>;
  erc20_?: InputMaybe<NftCollectionErc20Filter>;
  erc20s_?: InputMaybe<NftCollectionNftCollectionErc20Filter>;
  erc20Id?: InputMaybe<Scalars['String']['input']>;
  erc20Id_not?: InputMaybe<Scalars['String']['input']>;
  erc20Id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  erc20Id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  erc20Id_gt?: InputMaybe<Scalars['String']['input']>;
  erc20Id_lt?: InputMaybe<Scalars['String']['input']>;
  erc20Id_gte?: InputMaybe<Scalars['String']['input']>;
  erc20Id_lte?: InputMaybe<Scalars['String']['input']>;
  loanConfigs_?: InputMaybe<NftCollectionLoanConfigFilter>;
  activeLoanConfigsCount?: InputMaybe<Scalars['BigInt']['input']>;
  activeLoanConfigsCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  activeLoanConfigsCount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  activeLoanConfigsCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  activeLoanConfigsCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  activeLoanConfigsCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  activeLoanConfigsCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  activeLoanConfigsCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
};

export type NftCollectionErc20Filter = {
  id?: InputMaybe<Scalars['String']['input']>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_gt?: InputMaybe<Scalars['String']['input']>;
  id_lt?: InputMaybe<Scalars['String']['input']>;
  id_gte?: InputMaybe<Scalars['String']['input']>;
  id_lte?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  symbol_not?: InputMaybe<Scalars['String']['input']>;
  symbol_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  symbol_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  symbol_contains?: InputMaybe<Scalars['String']['input']>;
  symbol_not_contains?: InputMaybe<Scalars['String']['input']>;
  symbol_starts_with?: InputMaybe<Scalars['String']['input']>;
  symbol_ends_with?: InputMaybe<Scalars['String']['input']>;
  symbol_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  symbol_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  decimals?: InputMaybe<Scalars['Int']['input']>;
  decimals_not?: InputMaybe<Scalars['Int']['input']>;
  decimals_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  decimals_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  decimals_gt?: InputMaybe<Scalars['Int']['input']>;
  decimals_lt?: InputMaybe<Scalars['Int']['input']>;
  decimals_gte?: InputMaybe<Scalars['Int']['input']>;
  decimals_lte?: InputMaybe<Scalars['Int']['input']>;
};

export type NftCollectionNftCollectionErc20Filter = {
  id?: InputMaybe<Scalars['String']['input']>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_contains?: InputMaybe<Scalars['String']['input']>;
  id_not_contains?: InputMaybe<Scalars['String']['input']>;
  id_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_ends_with?: InputMaybe<Scalars['String']['input']>;
  id_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  erc20Id?: InputMaybe<Scalars['String']['input']>;
  erc20Id_not?: InputMaybe<Scalars['String']['input']>;
  erc20Id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  erc20Id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  erc20Id_gt?: InputMaybe<Scalars['String']['input']>;
  erc20Id_lt?: InputMaybe<Scalars['String']['input']>;
  erc20Id_gte?: InputMaybe<Scalars['String']['input']>;
  erc20Id_lte?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_not?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nftCollectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nftCollectionId_gt?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_lt?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_gte?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_lte?: InputMaybe<Scalars['String']['input']>;
};

export type NftCollectionLoanConfigFilter = {
  id?: InputMaybe<Scalars['String']['input']>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_contains?: InputMaybe<Scalars['String']['input']>;
  id_not_contains?: InputMaybe<Scalars['String']['input']>;
  id_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_ends_with?: InputMaybe<Scalars['String']['input']>;
  id_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  id_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  active_not?: InputMaybe<Scalars['Boolean']['input']>;
  active_in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  active_not_in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  maxAmount?: InputMaybe<Scalars['BigInt']['input']>;
  maxAmount_not?: InputMaybe<Scalars['BigInt']['input']>;
  maxAmount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  maxAmount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  maxAmount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  maxAmount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  maxAmount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  maxAmount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  minAmount?: InputMaybe<Scalars['BigInt']['input']>;
  minAmount_not?: InputMaybe<Scalars['BigInt']['input']>;
  minAmount_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  minAmount_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  minAmount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  minAmount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  minAmount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  minAmount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  maxDuration?: InputMaybe<Scalars['BigInt']['input']>;
  maxDuration_not?: InputMaybe<Scalars['BigInt']['input']>;
  maxDuration_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  maxDuration_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  maxDuration_gt?: InputMaybe<Scalars['BigInt']['input']>;
  maxDuration_lt?: InputMaybe<Scalars['BigInt']['input']>;
  maxDuration_gte?: InputMaybe<Scalars['BigInt']['input']>;
  maxDuration_lte?: InputMaybe<Scalars['BigInt']['input']>;
  maxInterest?: InputMaybe<Scalars['BigInt']['input']>;
  maxInterest_not?: InputMaybe<Scalars['BigInt']['input']>;
  maxInterest_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  maxInterest_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  maxInterest_gt?: InputMaybe<Scalars['BigInt']['input']>;
  maxInterest_lt?: InputMaybe<Scalars['BigInt']['input']>;
  maxInterest_gte?: InputMaybe<Scalars['BigInt']['input']>;
  maxInterest_lte?: InputMaybe<Scalars['BigInt']['input']>;
  minDuration?: InputMaybe<Scalars['BigInt']['input']>;
  minDuration_not?: InputMaybe<Scalars['BigInt']['input']>;
  minDuration_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  minDuration_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  minDuration_gt?: InputMaybe<Scalars['BigInt']['input']>;
  minDuration_lt?: InputMaybe<Scalars['BigInt']['input']>;
  minDuration_gte?: InputMaybe<Scalars['BigInt']['input']>;
  minDuration_lte?: InputMaybe<Scalars['BigInt']['input']>;
  minInterest?: InputMaybe<Scalars['BigInt']['input']>;
  minInterest_not?: InputMaybe<Scalars['BigInt']['input']>;
  minInterest_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  minInterest_not_in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  minInterest_gt?: InputMaybe<Scalars['BigInt']['input']>;
  minInterest_lt?: InputMaybe<Scalars['BigInt']['input']>;
  minInterest_gte?: InputMaybe<Scalars['BigInt']['input']>;
  minInterest_lte?: InputMaybe<Scalars['BigInt']['input']>;
  lendingDeskId?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_not?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lendingDeskId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lendingDeskId_contains?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_not_contains?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_starts_with?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_ends_with?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  lendingDeskId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_not?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nftCollectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nftCollectionId_gt?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_lt?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_gte?: InputMaybe<Scalars['String']['input']>;
  nftCollectionId_lte?: InputMaybe<Scalars['String']['input']>;
  nftCollectionIsErc1155?: InputMaybe<Scalars['Boolean']['input']>;
  nftCollectionIsErc1155_not?: InputMaybe<Scalars['Boolean']['input']>;
  nftCollectionIsErc1155_in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  nftCollectionIsErc1155_not_in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
};

export type _meta = {
  block?: Maybe<Scalars['Int']['output']>;
};

export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string | ((fieldNode: FieldNode) => SelectionSetNode);
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> = LegacyStitchingResolver<TResult, TParent, TContext, TArgs> | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;



/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  Query: ResolverTypeWrapper<{}>;
  Loan: ResolverTypeWrapper<Loan>;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  BigInt: ResolverTypeWrapper<Scalars['BigInt']['output']>;
  User: ResolverTypeWrapper<User>;
  LoanPage: ResolverTypeWrapper<LoanPage>;
  PageInfo: ResolverTypeWrapper<PageInfo>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  LoanFilter: LoanFilter;
  LoanUserFilter: LoanUserFilter;
  LoanStatus: LoanStatus;
  LoanUserFilter1: LoanUserFilter1;
  LoanLendingDeskFilter: LoanLendingDeskFilter;
  LendingDeskStatus: LendingDeskStatus;
  LoanNftCollectionFilter: LoanNftCollectionFilter;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  LendingDeskPage: ResolverTypeWrapper<LendingDeskPage>;
  LendingDesk: ResolverTypeWrapper<LendingDesk>;
  Erc20: ResolverTypeWrapper<Erc20>;
  LendingDeskFilter: LendingDeskFilter;
  LendingDeskErc20Filter: LendingDeskErc20Filter;
  LendingDeskLoanFilter: LendingDeskLoanFilter;
  LendingDeskUserFilter: LendingDeskUserFilter;
  LendingDeskLoanConfigFilter: LendingDeskLoanConfigFilter;
  NftCollectionErc20Page: ResolverTypeWrapper<NftCollectionErc20Page>;
  NftCollectionErc20: ResolverTypeWrapper<NftCollectionErc20>;
  NftCollection: ResolverTypeWrapper<NftCollection>;
  NftCollectionErc20Filter1: NftCollectionErc20Filter1;
  NftCollectionErc20Erc20Filter: NftCollectionErc20Erc20Filter;
  NftCollectionErc20NftCollectionFilter: NftCollectionErc20NftCollectionFilter;
  LoanConfigPage: ResolverTypeWrapper<LoanConfigPage>;
  LoanConfig: ResolverTypeWrapper<LoanConfig>;
  LoanConfigFilter: LoanConfigFilter;
  LoanConfigLendingDeskFilter: LoanConfigLendingDeskFilter;
  LoanConfigNftCollectionFilter: LoanConfigNftCollectionFilter;
  UserPage: ResolverTypeWrapper<UserPage>;
  UserFilter: UserFilter;
  UserLoanFilter: UserLoanFilter;
  UserLoanFilter1: UserLoanFilter1;
  UserLendingDeskFilter: UserLendingDeskFilter;
  Erc20Page: ResolverTypeWrapper<Erc20Page>;
  Erc20Filter: Erc20Filter;
  Erc20LendingDeskFilter: Erc20LendingDeskFilter;
  Erc20NftCollectionErc20Filter: Erc20NftCollectionErc20Filter;
  ProtocolInfo: ResolverTypeWrapper<ProtocolInfo>;
  ProtocolInfoPage: ResolverTypeWrapper<ProtocolInfoPage>;
  ProtocolInfoFilter: ProtocolInfoFilter;
  NftCollectionPage: ResolverTypeWrapper<NftCollectionPage>;
  NftCollectionFilter: NftCollectionFilter;
  NftCollectionErc20Filter: NftCollectionErc20Filter;
  NftCollectionNftCollectionErc20Filter: NftCollectionNftCollectionErc20Filter;
  NftCollectionLoanConfigFilter: NftCollectionLoanConfigFilter;
  _meta: ResolverTypeWrapper<_meta>;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  Query: {};
  Loan: Loan;
  String: Scalars['String']['output'];
  BigInt: Scalars['BigInt']['output'];
  User: User;
  LoanPage: LoanPage;
  PageInfo: PageInfo;
  Boolean: Scalars['Boolean']['output'];
  LoanFilter: LoanFilter;
  LoanUserFilter: LoanUserFilter;
  LoanUserFilter1: LoanUserFilter1;
  LoanLendingDeskFilter: LoanLendingDeskFilter;
  LoanNftCollectionFilter: LoanNftCollectionFilter;
  Int: Scalars['Int']['output'];
  LendingDeskPage: LendingDeskPage;
  LendingDesk: LendingDesk;
  Erc20: Erc20;
  LendingDeskFilter: LendingDeskFilter;
  LendingDeskErc20Filter: LendingDeskErc20Filter;
  LendingDeskLoanFilter: LendingDeskLoanFilter;
  LendingDeskUserFilter: LendingDeskUserFilter;
  LendingDeskLoanConfigFilter: LendingDeskLoanConfigFilter;
  NftCollectionErc20Page: NftCollectionErc20Page;
  NftCollectionErc20: NftCollectionErc20;
  NftCollection: NftCollection;
  NftCollectionErc20Filter1: NftCollectionErc20Filter1;
  NftCollectionErc20Erc20Filter: NftCollectionErc20Erc20Filter;
  NftCollectionErc20NftCollectionFilter: NftCollectionErc20NftCollectionFilter;
  LoanConfigPage: LoanConfigPage;
  LoanConfig: LoanConfig;
  LoanConfigFilter: LoanConfigFilter;
  LoanConfigLendingDeskFilter: LoanConfigLendingDeskFilter;
  LoanConfigNftCollectionFilter: LoanConfigNftCollectionFilter;
  UserPage: UserPage;
  UserFilter: UserFilter;
  UserLoanFilter: UserLoanFilter;
  UserLoanFilter1: UserLoanFilter1;
  UserLendingDeskFilter: UserLendingDeskFilter;
  Erc20Page: Erc20Page;
  Erc20Filter: Erc20Filter;
  Erc20LendingDeskFilter: Erc20LendingDeskFilter;
  Erc20NftCollectionErc20Filter: Erc20NftCollectionErc20Filter;
  ProtocolInfo: ProtocolInfo;
  ProtocolInfoPage: ProtocolInfoPage;
  ProtocolInfoFilter: ProtocolInfoFilter;
  NftCollectionPage: NftCollectionPage;
  NftCollectionFilter: NftCollectionFilter;
  NftCollectionErc20Filter: NftCollectionErc20Filter;
  NftCollectionNftCollectionErc20Filter: NftCollectionNftCollectionErc20Filter;
  NftCollectionLoanConfigFilter: NftCollectionLoanConfigFilter;
  _meta: _meta;
}>;

export type QueryResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = ResolversObject<{
  loan?: Resolver<Maybe<ResolversTypes['Loan']>, ParentType, ContextType, RequireFields<QueryloanArgs, 'id'>>;
  loans?: Resolver<ResolversTypes['LoanPage'], ParentType, ContextType, Partial<QueryloansArgs>>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryuserArgs, 'id'>>;
  users?: Resolver<ResolversTypes['UserPage'], ParentType, ContextType, Partial<QueryusersArgs>>;
  erc20?: Resolver<Maybe<ResolversTypes['Erc20']>, ParentType, ContextType, RequireFields<Queryerc20Args, 'id'>>;
  erc20s?: Resolver<ResolversTypes['Erc20Page'], ParentType, ContextType, Partial<Queryerc20sArgs>>;
  loanConfig?: Resolver<Maybe<ResolversTypes['LoanConfig']>, ParentType, ContextType, RequireFields<QueryloanConfigArgs, 'id'>>;
  loanConfigs?: Resolver<ResolversTypes['LoanConfigPage'], ParentType, ContextType, Partial<QueryloanConfigsArgs>>;
  lendingDesk?: Resolver<Maybe<ResolversTypes['LendingDesk']>, ParentType, ContextType, RequireFields<QuerylendingDeskArgs, 'id'>>;
  lendingDesks?: Resolver<ResolversTypes['LendingDeskPage'], ParentType, ContextType, Partial<QuerylendingDesksArgs>>;
  protocolInfo?: Resolver<Maybe<ResolversTypes['ProtocolInfo']>, ParentType, ContextType, RequireFields<QueryprotocolInfoArgs, 'id'>>;
  protocolInfos?: Resolver<ResolversTypes['ProtocolInfoPage'], ParentType, ContextType, Partial<QueryprotocolInfosArgs>>;
  nftCollection?: Resolver<Maybe<ResolversTypes['NftCollection']>, ParentType, ContextType, RequireFields<QuerynftCollectionArgs, 'id'>>;
  nftCollections?: Resolver<ResolversTypes['NftCollectionPage'], ParentType, ContextType, Partial<QuerynftCollectionsArgs>>;
  nftCollectionErc20?: Resolver<Maybe<ResolversTypes['NftCollectionErc20']>, ParentType, ContextType, RequireFields<QuerynftCollectionErc20Args, 'id'>>;
  nftCollectionErc20s?: Resolver<ResolversTypes['NftCollectionErc20Page'], ParentType, ContextType, Partial<QuerynftCollectionErc20sArgs>>;
  _meta?: Resolver<Maybe<ResolversTypes['_meta']>, ParentType, ContextType>;
}>;

export type LoanResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Loan'] = ResolversParentTypes['Loan']> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nftId?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  lender?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['LoanStatus'], ParentType, ContextType>;
  txHash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  borrower?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  duration?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  interest?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  lenderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startTime?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  borrowerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lendingDesk?: Resolver<ResolversTypes['LendingDesk'], ParentType, ContextType>;
  lendingDeskId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nftCollection?: Resolver<ResolversTypes['NftCollection'], ParentType, ContextType>;
  amountPaidBack?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  nftCollectionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface BigIntScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['BigInt'], any> {
  name: 'BigInt';
}

export type UserResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  loansTaken?: Resolver<Maybe<ResolversTypes['LoanPage']>, ParentType, ContextType, Partial<UserloansTakenArgs>>;
  loansIssued?: Resolver<Maybe<ResolversTypes['LoanPage']>, ParentType, ContextType, Partial<UserloansIssuedArgs>>;
  lendingDesks?: Resolver<Maybe<ResolversTypes['LendingDeskPage']>, ParentType, ContextType, Partial<UserlendingDesksArgs>>;
  loansTakenCount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  loansIssuedCount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  loansTakenResolvedCount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  loansIssuedResolvedCount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  loansTakenDefaultedCount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  loansIssuedDefaultedCount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LoanPageResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['LoanPage'] = ResolversParentTypes['LoanPage']> = ResolversObject<{
  items?: Resolver<Array<ResolversTypes['Loan']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PageInfoResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo']> = ResolversObject<{
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  startCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LendingDeskPageResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['LendingDeskPage'] = ResolversParentTypes['LendingDeskPage']> = ResolversObject<{
  items?: Resolver<Array<ResolversTypes['LendingDesk']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LendingDeskResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['LendingDesk'] = ResolversParentTypes['LendingDesk']> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  erc20?: Resolver<ResolversTypes['Erc20'], ParentType, ContextType>;
  loans?: Resolver<Maybe<ResolversTypes['LoanPage']>, ParentType, ContextType, Partial<LendingDeskloansArgs>>;
  owner?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['LendingDeskStatus'], ParentType, ContextType>;
  balance?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  erc20Id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ownerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  netProfit?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  loansCount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  loanConfigs?: Resolver<Maybe<ResolversTypes['LoanConfigPage']>, ParentType, ContextType, Partial<LendingDeskloanConfigsArgs>>;
  amountBorrowed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  loansResolvedCount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  netLiquidityIssued?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  loansDefaultedCount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Erc20Resolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Erc20'] = ResolversParentTypes['Erc20']> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  decimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  lendingDesks?: Resolver<Maybe<ResolversTypes['LendingDeskPage']>, ParentType, ContextType, Partial<Erc20lendingDesksArgs>>;
  nftCollections?: Resolver<Maybe<ResolversTypes['NftCollectionErc20Page']>, ParentType, ContextType, Partial<Erc20nftCollectionsArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NftCollectionErc20PageResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['NftCollectionErc20Page'] = ResolversParentTypes['NftCollectionErc20Page']> = ResolversObject<{
  items?: Resolver<Array<ResolversTypes['NftCollectionErc20']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NftCollectionErc20Resolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['NftCollectionErc20'] = ResolversParentTypes['NftCollectionErc20']> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  erc20?: Resolver<ResolversTypes['Erc20'], ParentType, ContextType>;
  erc20Id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nftCollection?: Resolver<ResolversTypes['NftCollection'], ParentType, ContextType>;
  nftCollectionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NftCollectionResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['NftCollection'] = ResolversParentTypes['NftCollection']> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  erc20?: Resolver<ResolversTypes['Erc20'], ParentType, ContextType>;
  erc20s?: Resolver<Maybe<ResolversTypes['NftCollectionErc20Page']>, ParentType, ContextType, Partial<NftCollectionerc20sArgs>>;
  erc20Id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  loanConfigs?: Resolver<Maybe<ResolversTypes['LoanConfigPage']>, ParentType, ContextType, Partial<NftCollectionloanConfigsArgs>>;
  activeLoanConfigsCount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LoanConfigPageResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['LoanConfigPage'] = ResolversParentTypes['LoanConfigPage']> = ResolversObject<{
  items?: Resolver<Array<ResolversTypes['LoanConfig']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LoanConfigResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['LoanConfig'] = ResolversParentTypes['LoanConfig']> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  maxAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  minAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  lendingDesk?: Resolver<ResolversTypes['LendingDesk'], ParentType, ContextType>;
  maxDuration?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  maxInterest?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  minDuration?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  minInterest?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  lendingDeskId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nftCollection?: Resolver<ResolversTypes['NftCollection'], ParentType, ContextType>;
  nftCollectionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nftCollectionIsErc1155?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserPageResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['UserPage'] = ResolversParentTypes['UserPage']> = ResolversObject<{
  items?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Erc20PageResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Erc20Page'] = ResolversParentTypes['Erc20Page']> = ResolversObject<{
  items?: Resolver<Array<ResolversTypes['Erc20']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProtocolInfoResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['ProtocolInfo'] = ResolversParentTypes['ProtocolInfo']> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  owner?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paused?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  loansCount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  erc20sCount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  lendingKeys?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  platformWallet?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  obligationNotes?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lendingDesksCount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  loanOriginationFee?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  nftCollectionsCount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProtocolInfoPageResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['ProtocolInfoPage'] = ResolversParentTypes['ProtocolInfoPage']> = ResolversObject<{
  items?: Resolver<Array<ResolversTypes['ProtocolInfo']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NftCollectionPageResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['NftCollectionPage'] = ResolversParentTypes['NftCollectionPage']> = ResolversObject<{
  items?: Resolver<Array<ResolversTypes['NftCollection']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type _metaResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['_meta'] = ResolversParentTypes['_meta']> = ResolversObject<{
  block?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Resolvers<ContextType = MeshContext> = ResolversObject<{
  Query?: QueryResolvers<ContextType>;
  Loan?: LoanResolvers<ContextType>;
  BigInt?: GraphQLScalarType;
  User?: UserResolvers<ContextType>;
  LoanPage?: LoanPageResolvers<ContextType>;
  PageInfo?: PageInfoResolvers<ContextType>;
  LendingDeskPage?: LendingDeskPageResolvers<ContextType>;
  LendingDesk?: LendingDeskResolvers<ContextType>;
  Erc20?: Erc20Resolvers<ContextType>;
  NftCollectionErc20Page?: NftCollectionErc20PageResolvers<ContextType>;
  NftCollectionErc20?: NftCollectionErc20Resolvers<ContextType>;
  NftCollection?: NftCollectionResolvers<ContextType>;
  LoanConfigPage?: LoanConfigPageResolvers<ContextType>;
  LoanConfig?: LoanConfigResolvers<ContextType>;
  UserPage?: UserPageResolvers<ContextType>;
  Erc20Page?: Erc20PageResolvers<ContextType>;
  ProtocolInfo?: ProtocolInfoResolvers<ContextType>;
  ProtocolInfoPage?: ProtocolInfoPageResolvers<ContextType>;
  NftCollectionPage?: NftCollectionPageResolvers<ContextType>;
  _meta?: _metaResolvers<ContextType>;
}>;


export type MeshContext = MagnifyCashTypes.Context & BaseMeshContext;


import { fileURLToPath } from '@graphql-mesh/utils';
const baseDir = pathModule.join(pathModule.dirname(fileURLToPath(import.meta.url)), '..');

const importFn: ImportFn = <T>(moduleId: string) => {
  const relativeModuleId = (pathModule.isAbsolute(moduleId) ? pathModule.relative(baseDir, moduleId) : moduleId).split('\\').join('/').replace(baseDir + '/', '');
  switch(relativeModuleId) {
    case ".graphclient/sources/magnifyCash/introspectionSchema":
      return Promise.resolve(importedModule$0) as T;
    
    default:
      return Promise.reject(new Error(`Cannot find module '${relativeModuleId}'.`));
  }
};

const rootStore = new MeshStore('.graphclient', new FsStoreStorageAdapter({
  cwd: baseDir,
  importFn,
  fileType: "ts",
}), {
  readonly: true,
  validate: false
});

export const rawServeConfig: YamlConfig.Config['serve'] = undefined as any
export async function getMeshOptions(): Promise<GetMeshOptions> {
const pubsub = new PubSub();
const sourcesStore = rootStore.child('sources');
const logger = new DefaultLogger("GraphClient");
const cache = new (MeshCache as any)({
      ...({} as any),
      importFn,
      store: rootStore.child('cache'),
      pubsub,
      logger,
    } as any)

const sources: MeshResolvedSource[] = [];
const transforms: MeshTransform[] = [];
const additionalEnvelopPlugins: MeshPlugin<any>[] = [];
const magnifyCashTransforms = [];
const additionalTypeDefs = [] as any[];
const magnifyCashHandler = new GraphqlHandler({
              name: "magnifyCash",
              config: {"endpoint":"https://api.ghostlogs.xyz/gg/pub/8bf67182-75f2-41e8-ae10-bd4d26d9c91e/ghostgraph"},
              baseDir,
              cache,
              pubsub,
              store: sourcesStore.child("magnifyCash"),
              logger: logger.child("magnifyCash"),
              importFn,
            });
sources[0] = {
          name: 'magnifyCash',
          handler: magnifyCashHandler,
          transforms: magnifyCashTransforms
        }
const additionalResolvers = [] as any[]
const merger = new(BareMerger as any)({
        cache,
        pubsub,
        logger: logger.child('bareMerger'),
        store: rootStore.child('bareMerger')
      })
const documentHashMap = {
        "51c13663b11259f4772876306a5b8ff08a93c80c50eac8534b884b2612c322fa": HomeDocument,
"51c13663b11259f4772876306a5b8ff08a93c80c50eac8534b884b2612c322fa": ManageLendingDesksDocument,
"51c13663b11259f4772876306a5b8ff08a93c80c50eac8534b884b2612c322fa": CreateLendingDeskDocument,
"51c13663b11259f4772876306a5b8ff08a93c80c50eac8534b884b2612c322fa": ManageLendingDeskDocument,
"51c13663b11259f4772876306a5b8ff08a93c80c50eac8534b884b2612c322fa": BrowseCollectionsDocument,
"51c13663b11259f4772876306a5b8ff08a93c80c50eac8534b884b2612c322fa": BrowseCollectionDocument,
"51c13663b11259f4772876306a5b8ff08a93c80c50eac8534b884b2612c322fa": QuickLoanDocument,
"51c13663b11259f4772876306a5b8ff08a93c80c50eac8534b884b2612c322fa": GetNftsWithLendingDeksDocument,
"51c13663b11259f4772876306a5b8ff08a93c80c50eac8534b884b2612c322fa": GetErc20sForNftCollectionDocument,
"51c13663b11259f4772876306a5b8ff08a93c80c50eac8534b884b2612c322fa": BorrowerDashboardDocument,
"51c13663b11259f4772876306a5b8ff08a93c80c50eac8534b884b2612c322fa": LenderDashboardDocument
      }
additionalEnvelopPlugins.push(usePersistedOperations({
        getPersistedOperation(key) {
          return documentHashMap[key];
        },
        ...{}
      }))

  return {
    sources,
    transforms,
    additionalTypeDefs,
    additionalResolvers,
    cache,
    pubsub,
    merger,
    logger,
    additionalEnvelopPlugins,
    get documents() {
      return [
      {
        document: HomeDocument,
        get rawSDL() {
          return printWithCache(HomeDocument);
        },
        location: 'HomeDocument.graphql',
        sha256Hash: '51c13663b11259f4772876306a5b8ff08a93c80c50eac8534b884b2612c322fa'
      },{
        document: ManageLendingDesksDocument,
        get rawSDL() {
          return printWithCache(ManageLendingDesksDocument);
        },
        location: 'ManageLendingDesksDocument.graphql',
        sha256Hash: '51c13663b11259f4772876306a5b8ff08a93c80c50eac8534b884b2612c322fa'
      },{
        document: CreateLendingDeskDocument,
        get rawSDL() {
          return printWithCache(CreateLendingDeskDocument);
        },
        location: 'CreateLendingDeskDocument.graphql',
        sha256Hash: '51c13663b11259f4772876306a5b8ff08a93c80c50eac8534b884b2612c322fa'
      },{
        document: ManageLendingDeskDocument,
        get rawSDL() {
          return printWithCache(ManageLendingDeskDocument);
        },
        location: 'ManageLendingDeskDocument.graphql',
        sha256Hash: '51c13663b11259f4772876306a5b8ff08a93c80c50eac8534b884b2612c322fa'
      },{
        document: BrowseCollectionsDocument,
        get rawSDL() {
          return printWithCache(BrowseCollectionsDocument);
        },
        location: 'BrowseCollectionsDocument.graphql',
        sha256Hash: '51c13663b11259f4772876306a5b8ff08a93c80c50eac8534b884b2612c322fa'
      },{
        document: BrowseCollectionDocument,
        get rawSDL() {
          return printWithCache(BrowseCollectionDocument);
        },
        location: 'BrowseCollectionDocument.graphql',
        sha256Hash: '51c13663b11259f4772876306a5b8ff08a93c80c50eac8534b884b2612c322fa'
      },{
        document: QuickLoanDocument,
        get rawSDL() {
          return printWithCache(QuickLoanDocument);
        },
        location: 'QuickLoanDocument.graphql',
        sha256Hash: '51c13663b11259f4772876306a5b8ff08a93c80c50eac8534b884b2612c322fa'
      },{
        document: GetNftsWithLendingDeksDocument,
        get rawSDL() {
          return printWithCache(GetNftsWithLendingDeksDocument);
        },
        location: 'GetNftsWithLendingDeksDocument.graphql',
        sha256Hash: '51c13663b11259f4772876306a5b8ff08a93c80c50eac8534b884b2612c322fa'
      },{
        document: GetErc20sForNftCollectionDocument,
        get rawSDL() {
          return printWithCache(GetErc20sForNftCollectionDocument);
        },
        location: 'GetErc20sForNftCollectionDocument.graphql',
        sha256Hash: '51c13663b11259f4772876306a5b8ff08a93c80c50eac8534b884b2612c322fa'
      },{
        document: BorrowerDashboardDocument,
        get rawSDL() {
          return printWithCache(BorrowerDashboardDocument);
        },
        location: 'BorrowerDashboardDocument.graphql',
        sha256Hash: '51c13663b11259f4772876306a5b8ff08a93c80c50eac8534b884b2612c322fa'
      },{
        document: LenderDashboardDocument,
        get rawSDL() {
          return printWithCache(LenderDashboardDocument);
        },
        location: 'LenderDashboardDocument.graphql',
        sha256Hash: '51c13663b11259f4772876306a5b8ff08a93c80c50eac8534b884b2612c322fa'
      }
    ];
    },
    fetchFn,
  };
}

export function createBuiltMeshHTTPHandler<TServerContext = {}>(): MeshHTTPHandler<TServerContext> {
  return createMeshHTTPHandler<TServerContext>({
    baseDir,
    getBuiltMesh: getBuiltGraphClient,
    rawServeConfig: undefined,
  })
}


let meshInstance$: Promise<MeshInstance> | undefined;

export const pollingInterval = null;

export function getBuiltGraphClient(): Promise<MeshInstance> {
  if (meshInstance$ == null) {
    if (pollingInterval) {
      setInterval(() => {
        getMeshOptions()
        .then(meshOptions => getMesh(meshOptions))
        .then(newMesh =>
          meshInstance$.then(oldMesh => {
            oldMesh.destroy()
            meshInstance$ = Promise.resolve(newMesh)
          })
        ).catch(err => {
          console.error("Mesh polling failed so the existing version will be used:", err);
        });
      }, pollingInterval)
    }
    meshInstance$ = getMeshOptions().then(meshOptions => getMesh(meshOptions)).then(mesh => {
      const id = mesh.pubsub.subscribe('destroy', () => {
        meshInstance$ = undefined;
        mesh.pubsub.unsubscribe(id);
      });
      return mesh;
    });
  }
  return meshInstance$;
}

export const execute: ExecuteMeshFn = (...args) => getBuiltGraphClient().then(({ execute }) => execute(...args));

export const subscribe: SubscribeMeshFn = (...args) => getBuiltGraphClient().then(({ subscribe }) => subscribe(...args));
export function getBuiltGraphSDK<TGlobalContext = any, TOperationContext = any>(globalContext?: TGlobalContext) {
  const sdkRequester$ = getBuiltGraphClient().then(({ sdkRequesterFactory }) => sdkRequesterFactory(globalContext));
  return getSdk<TOperationContext, TGlobalContext>((...args) => sdkRequester$.then(sdkRequester => sdkRequester(...args)));
}
export type HomeQueryVariables = Exact<{ [key: string]: never; }>;


export type HomeQuery = { protocolInfo?: Maybe<Pick<ProtocolInfo, 'id' | 'lendingDesksCount' | 'lendingKeys' | 'loanOriginationFee' | 'loansCount' | 'obligationNotes' | 'owner' | 'paused' | 'platformWallet' | 'erc20sCount' | 'nftCollectionsCount'>> };

export type ManageLendingDesksQueryVariables = Exact<{
  walletAddress: Scalars['String']['input'];
  status: LendingDeskStatus;
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type ManageLendingDesksQuery = { lendingDesks: { items: Array<(
      Pick<LendingDesk, 'id' | 'balance' | 'status' | 'loansCount' | 'loansDefaultedCount' | 'loansResolvedCount' | 'amountBorrowed' | 'netLiquidityIssued' | 'netProfit'>
      & { erc20: Pick<Erc20, 'id' | 'symbol' | 'decimals'>, loanConfigs?: Maybe<{ items: Array<Pick<LoanConfig, 'id'>> }>, loans?: Maybe<{ items: Array<Pick<Loan, 'status'>> }> }
    )>, pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'> } };

export type CreateLendingDeskQueryVariables = Exact<{
  walletAddress: Scalars['String']['input'];
}>;


export type CreateLendingDeskQuery = { lendingDesks: { items: Array<Pick<LendingDesk, 'id' | 'balance' | 'status'>> } };

export type ManageLendingDeskQueryVariables = Exact<{
  deskId: Scalars['String']['input'];
}>;


export type ManageLendingDeskQuery = { lendingDesk?: Maybe<(
    Pick<LendingDesk, 'id' | 'balance' | 'status'>
    & { owner: Pick<User, 'id'>, erc20: Pick<Erc20, 'id' | 'symbol' | 'decimals'>, loanConfigs?: Maybe<{ items: Array<(
        Pick<LoanConfig, 'id' | 'minAmount' | 'maxAmount' | 'minInterest' | 'maxInterest' | 'minDuration' | 'maxDuration' | 'nftCollectionIsErc1155'>
        & { nftCollection: Pick<NftCollection, 'id'> }
      )> }>, loans?: Maybe<{ items: Array<Pick<Loan, 'status'>> }> }
  )> };

export type BrowseCollectionsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type BrowseCollectionsQuery = { nftCollections: { items: Array<(
      Pick<NftCollection, 'id' | 'activeLoanConfigsCount'>
      & { loanConfigs?: Maybe<{ items: Array<{ lendingDesk: { erc20: Pick<Erc20, 'id' | 'symbol' | 'decimals'> } }> }> }
    )>, pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'> }, protocolInfo?: Maybe<Pick<ProtocolInfo, 'erc20sCount' | 'nftCollectionsCount'>> };

export type BrowseCollectionQueryVariables = Exact<{
  nftCollectionId?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type BrowseCollectionQuery = { lendingDesks: { items: Array<(
      Pick<LendingDesk, 'id' | 'balance' | 'status'>
      & { owner: Pick<User, 'id'>, erc20: Pick<Erc20, 'id' | 'symbol' | 'decimals'>, loanConfigs?: Maybe<{ items: Array<(
          Pick<LoanConfig, 'minAmount' | 'maxAmount' | 'minInterest' | 'maxInterest' | 'minDuration' | 'maxDuration'>
          & { nftCollection: Pick<NftCollection, 'id'> }
        )> }> }
    )>, pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'> } };

export type QuickLoanQueryVariables = Exact<{
  erc20Id: Scalars['String']['input'];
  nftCollectionId: Scalars['String']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type QuickLoanQuery = { lendingDesks: { items: Array<(
      Pick<LendingDesk, 'id' | 'balance' | 'status'>
      & { erc20: Pick<Erc20, 'id' | 'symbol' | 'decimals'>, loanConfigs?: Maybe<{ items: Array<(
          Pick<LoanConfig, 'minAmount' | 'maxAmount' | 'minDuration' | 'maxDuration' | 'minInterest' | 'maxInterest'>
          & { nftCollection: Pick<NftCollection, 'id'> }
        )> }> }
    )>, pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'> }, nftCollections: { items: Array<Pick<NftCollection, 'id'>> } };

export type GetNftsWithLendingDeksQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNftsWithLendingDeksQuery = { nftCollections: { items: Array<Pick<NftCollection, 'id'>>, pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'> } };

export type GetErc20sForNftCollectionQueryVariables = Exact<{
  nftCollectionId: Scalars['String']['input'];
}>;


export type GetErc20sForNftCollectionQuery = { nftCollection?: Maybe<{ erc20s?: Maybe<{ items: Array<{ erc20: Pick<Erc20, 'id'> }> }> }> };

export type BorrowerDashboardQueryVariables = Exact<{
  walletAddress: Scalars['String']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  status: LoanStatus;
}>;


export type BorrowerDashboardQuery = { loans: { items: Array<(
      Pick<Loan, 'id' | 'amount' | 'amountPaidBack' | 'duration' | 'startTime' | 'nftId' | 'interest' | 'status'>
      & { nftCollection: Pick<NftCollection, 'id'>, lendingDesk: { erc20: Pick<Erc20, 'id' | 'symbol' | 'decimals'> }, lender: Pick<User, 'id'> }
    )>, pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'> } };

export type LenderDashboardQueryVariables = Exact<{
  walletAddress: Scalars['String']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  status: LoanStatus;
}>;


export type LenderDashboardQuery = { loans: { items: Array<(
      Pick<Loan, 'id' | 'amount' | 'amountPaidBack' | 'duration' | 'startTime' | 'nftId' | 'interest' | 'status'>
      & { nftCollection: Pick<NftCollection, 'id'>, lendingDesk: (
        Pick<LendingDesk, 'id'>
        & { erc20: Pick<Erc20, 'id' | 'symbol' | 'decimals'> }
      ), lender: Pick<User, 'id'> }
    )>, pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'> } };


export const HomeDocument = gql`
    query Home {
  protocolInfo(id: "0") {
    id
    lendingDesksCount
    lendingKeys
    loanOriginationFee
    loansCount
    obligationNotes
    owner
    paused
    platformWallet
    erc20sCount
    nftCollectionsCount
  }
}
    ` as unknown as DocumentNode<HomeQuery, HomeQueryVariables>;
export const ManageLendingDesksDocument = gql`
    query ManageLendingDesks($walletAddress: String!, $status: LendingDeskStatus!, $after: String) {
  lendingDesks(
    where: {ownerId: $walletAddress, status: $status}
    orderBy: "id"
    orderDirection: "desc"
    after: $after
    limit: 10
  ) {
    items {
      id
      balance
      status
      loansCount
      loansDefaultedCount
      loansResolvedCount
      amountBorrowed
      netLiquidityIssued
      netProfit
      erc20 {
        id
        symbol
        decimals
      }
      loanConfigs(where: {active: true}) {
        items {
          id
        }
      }
      loans {
        items {
          status
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ` as unknown as DocumentNode<ManageLendingDesksQuery, ManageLendingDesksQueryVariables>;
export const CreateLendingDeskDocument = gql`
    query CreateLendingDesk($walletAddress: String!) {
  lendingDesks(where: {ownerId: $walletAddress}) {
    items {
      id
      balance
      status
    }
  }
}
    ` as unknown as DocumentNode<CreateLendingDeskQuery, CreateLendingDeskQueryVariables>;
export const ManageLendingDeskDocument = gql`
    query ManageLendingDesk($deskId: String!) {
  lendingDesk(id: $deskId) {
    id
    owner {
      id
    }
    erc20 {
      id
      symbol
      decimals
    }
    balance
    status
    loanConfigs(where: {active: true}) {
      items {
        nftCollection {
          id
        }
        id
        minAmount
        maxAmount
        minInterest
        maxInterest
        minDuration
        maxDuration
        nftCollectionIsErc1155
      }
    }
    loans {
      items {
        status
      }
    }
  }
}
    ` as unknown as DocumentNode<ManageLendingDeskQuery, ManageLendingDeskQueryVariables>;
export const BrowseCollectionsDocument = gql`
    query BrowseCollections($after: String) {
  nftCollections(
    where: {activeLoanConfigsCount_gt: "0"}
    orderBy: "activeLoanConfigsCount"
    orderDirection: "desc"
    after: $after
    limit: 100
  ) {
    items {
      id
      activeLoanConfigsCount
      loanConfigs {
        items {
          lendingDesk {
            erc20 {
              id
              symbol
              decimals
            }
          }
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  protocolInfo(id: "0") {
    erc20sCount
    nftCollectionsCount
  }
}
    ` as unknown as DocumentNode<BrowseCollectionsQuery, BrowseCollectionsQueryVariables>;
export const BrowseCollectionDocument = gql`
    query BrowseCollection($nftCollectionId: String, $after: String) {
  lendingDesks(
    where: {status: Active, balance_gt: "0"}
    orderBy: "balance"
    orderDirection: "desc"
    after: $after
    limit: 500
  ) {
    items {
      id
      balance
      status
      owner {
        id
      }
      erc20 {
        id
        symbol
        decimals
      }
      loanConfigs(where: {nftCollectionId: $nftCollectionId, active: true}) {
        items {
          nftCollection {
            id
          }
          minAmount
          maxAmount
          minInterest
          maxInterest
          minDuration
          maxDuration
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ` as unknown as DocumentNode<BrowseCollectionQuery, BrowseCollectionQueryVariables>;
export const QuickLoanDocument = gql`
    query QuickLoan($erc20Id: String!, $nftCollectionId: String!, $after: String) {
  lendingDesks(
    where: {erc20Id: $erc20Id, status: Active, balance_gt: "0"}
    orderBy: "balance"
    orderDirection: "desc"
    after: $after
    limit: 50
  ) {
    items {
      id
      balance
      status
      erc20 {
        id
        symbol
        decimals
      }
      loanConfigs(where: {nftCollectionId: $nftCollectionId, active: true}) {
        items {
          minAmount
          maxAmount
          minDuration
          maxDuration
          minInterest
          maxInterest
          nftCollection {
            id
          }
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  nftCollections(where: {activeLoanConfigsCount_gt: "0"}) {
    items {
      id
    }
  }
}
    ` as unknown as DocumentNode<QuickLoanQuery, QuickLoanQueryVariables>;
export const GetNftsWithLendingDeksDocument = gql`
    query GetNftsWithLendingDeks {
  nftCollections(where: {activeLoanConfigsCount_gt: "0"}, limit: 500) {
    items {
      id
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ` as unknown as DocumentNode<GetNftsWithLendingDeksQuery, GetNftsWithLendingDeksQueryVariables>;
export const GetErc20sForNftCollectionDocument = gql`
    query GetErc20sForNftCollection($nftCollectionId: String!) {
  nftCollection(id: $nftCollectionId) {
    erc20s {
      items {
        erc20 {
          id
        }
      }
    }
  }
}
    ` as unknown as DocumentNode<GetErc20sForNftCollectionQuery, GetErc20sForNftCollectionQueryVariables>;
export const BorrowerDashboardDocument = gql`
    query BorrowerDashboard($walletAddress: String!, $after: String, $status: LoanStatus!) {
  loans(
    where: {borrowerId: $walletAddress, status: $status}
    orderBy: "startTime"
    orderDirection: "desc"
    after: $after
    limit: 15
  ) {
    items {
      id
      amount
      amountPaidBack
      duration
      startTime
      nftCollection {
        id
      }
      lendingDesk {
        erc20 {
          id
          symbol
          decimals
        }
      }
      nftId
      interest
      status
      lender {
        id
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ` as unknown as DocumentNode<BorrowerDashboardQuery, BorrowerDashboardQueryVariables>;
export const LenderDashboardDocument = gql`
    query LenderDashboard($walletAddress: String!, $after: String, $status: LoanStatus!) {
  loans(
    where: {lenderId: $walletAddress, status: $status}
    orderBy: "startTime"
    orderDirection: "desc"
    after: $after
    limit: 15
  ) {
    items {
      id
      amount
      amountPaidBack
      duration
      startTime
      nftCollection {
        id
      }
      lendingDesk {
        id
        erc20 {
          id
          symbol
          decimals
        }
      }
      nftId
      interest
      status
      lender {
        id
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ` as unknown as DocumentNode<LenderDashboardQuery, LenderDashboardQueryVariables>;












export type Requester<C = {}, E = unknown> = <R, V>(doc: DocumentNode, vars?: V, options?: C) => Promise<R> | AsyncIterable<R>
export function getSdk<C, E>(requester: Requester<C, E>) {
  return {
    Home(variables?: HomeQueryVariables, options?: C): Promise<HomeQuery> {
      return requester<HomeQuery, HomeQueryVariables>(HomeDocument, variables, options) as Promise<HomeQuery>;
    },
    ManageLendingDesks(variables: ManageLendingDesksQueryVariables, options?: C): Promise<ManageLendingDesksQuery> {
      return requester<ManageLendingDesksQuery, ManageLendingDesksQueryVariables>(ManageLendingDesksDocument, variables, options) as Promise<ManageLendingDesksQuery>;
    },
    CreateLendingDesk(variables: CreateLendingDeskQueryVariables, options?: C): Promise<CreateLendingDeskQuery> {
      return requester<CreateLendingDeskQuery, CreateLendingDeskQueryVariables>(CreateLendingDeskDocument, variables, options) as Promise<CreateLendingDeskQuery>;
    },
    ManageLendingDesk(variables: ManageLendingDeskQueryVariables, options?: C): Promise<ManageLendingDeskQuery> {
      return requester<ManageLendingDeskQuery, ManageLendingDeskQueryVariables>(ManageLendingDeskDocument, variables, options) as Promise<ManageLendingDeskQuery>;
    },
    BrowseCollections(variables?: BrowseCollectionsQueryVariables, options?: C): Promise<BrowseCollectionsQuery> {
      return requester<BrowseCollectionsQuery, BrowseCollectionsQueryVariables>(BrowseCollectionsDocument, variables, options) as Promise<BrowseCollectionsQuery>;
    },
    BrowseCollection(variables?: BrowseCollectionQueryVariables, options?: C): Promise<BrowseCollectionQuery> {
      return requester<BrowseCollectionQuery, BrowseCollectionQueryVariables>(BrowseCollectionDocument, variables, options) as Promise<BrowseCollectionQuery>;
    },
    QuickLoan(variables: QuickLoanQueryVariables, options?: C): Promise<QuickLoanQuery> {
      return requester<QuickLoanQuery, QuickLoanQueryVariables>(QuickLoanDocument, variables, options) as Promise<QuickLoanQuery>;
    },
    GetNftsWithLendingDeks(variables?: GetNftsWithLendingDeksQueryVariables, options?: C): Promise<GetNftsWithLendingDeksQuery> {
      return requester<GetNftsWithLendingDeksQuery, GetNftsWithLendingDeksQueryVariables>(GetNftsWithLendingDeksDocument, variables, options) as Promise<GetNftsWithLendingDeksQuery>;
    },
    GetErc20sForNftCollection(variables: GetErc20sForNftCollectionQueryVariables, options?: C): Promise<GetErc20sForNftCollectionQuery> {
      return requester<GetErc20sForNftCollectionQuery, GetErc20sForNftCollectionQueryVariables>(GetErc20sForNftCollectionDocument, variables, options) as Promise<GetErc20sForNftCollectionQuery>;
    },
    BorrowerDashboard(variables: BorrowerDashboardQueryVariables, options?: C): Promise<BorrowerDashboardQuery> {
      return requester<BorrowerDashboardQuery, BorrowerDashboardQueryVariables>(BorrowerDashboardDocument, variables, options) as Promise<BorrowerDashboardQuery>;
    },
    LenderDashboard(variables: LenderDashboardQueryVariables, options?: C): Promise<LenderDashboardQuery> {
      return requester<LenderDashboardQuery, LenderDashboardQueryVariables>(LenderDashboardDocument, variables, options) as Promise<LenderDashboardQuery>;
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;